import '../App.css';
import React, {useContext, useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive'
import ResponsiveCallouts from '../components/container/responsive-callouts';
import './landingpage.css';
import AppStateContext from "../contexts/appStateContext";
import LoadingScreen from "../components/container/LoadingScreen";

const Landingpage = ({ changeLayeredMovies, changeBackgroundImage, isBackgroundReady }) => {
    const { setMobileBackButtonVisible } = useContext(AppStateContext);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 700px)' });
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

    const [backgroundChanged, setBackgroundChanged] = useState(false);


    useEffect(() => {
        if (isDesktopOrLaptop) {
            changeLayeredMovies(["Loop_Totale_Nur_WindWasser[00000-00248].webm", "Loop_Totale_Autos_[00000-01499]_small.webm"]);//, "Loop_Totale_Autos_[00001-01499].webm"]);
            changeBackgroundImage("empty_page.png");
        } else {
            changeBackgroundImage("Totale-Still.jpg");
            changeLayeredMovies([]);
        }
        setBackgroundChanged(true);
    }, []);

    useEffect(() => {
        setMobileBackButtonVisible(false);
    }, [setMobileBackButtonVisible]);


    if (backgroundChanged && isBackgroundReady) {
        return (
          <main>
              <ResponsiveCallouts />
          </main>
        );
    }
    return (
      <LoadingScreen />
    );
}

export default Landingpage;
