import React from 'react';
import '../../container/mobile/mobileproductsdetailcontainer.css';
import HtmlParser from "../../../misc/HtmlParser";
import styled from "styled-components";

const Heading = styled.h3`
    text-align: left;
    font-size: xx-large;
`;

const Text = styled.div`
    font-size: small;
    font-weight: 400;
`;

function MobileSustainabilityDetails({ year }) {
    return (
      <>
        <Heading>{("" + year.year + "").toUpperCase()}</Heading>
        <Text>
          <HtmlParser string={year.free_text} />
        </Text>
      </>
    );
}

export default MobileSustainabilityDetails;
