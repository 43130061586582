import React, { useCallback } from "react";
import styled from "styled-components";

const Header = styled.div`
  background-color: var(--heading-background-color);
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 3%;
  padding: 0 12px;
`;

const StyledButton = styled.button`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  border: none;
  outline: none;
  background-color: var(${props => props.active ? "--main-handtmann-color" : "--main-handtmann-color-inactive"});
  opacity: ${props => props.active ? 1 : 0.95};
  color: white;
  min-height: 34px;
  text-align: center;
  font-size: 18.24px;
  font-weight: bold;
  line-height: 34px;
  user-select: none;
  
  &:not(:last-child) {
    margin-bottom: 3%;
  }
`;

function LocationButton({ location, currentLocation, changeLocation }) {
  const selectCallback = useCallback(() => {
    window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', location.locationName.toUpperCase()]);
    changeLocation(location.year);
  }, [location, changeLocation]);

  return (
    <StyledButton onClick={selectCallback} active={location.year === currentLocation.year}>
      {location.locationName.toUpperCase()}
    </StyledButton>
  );
}

function MobileLocationSelection({ locations, currentLocation, changeLocation }) {
  return (
    <div>
      <Header>Locations</Header>
      {locations.map(location => <LocationButton key={location.year} location={location} currentLocation={currentLocation} changeLocation={changeLocation} />)}
    </div>
  );
}

export default MobileLocationSelection;
