import React, {useContext, useEffect} from "react";

import AppStateContext from "../../contexts/appStateContext";
import styled from "styled-components";
import MobileLocationSelection from "./components/MobileLocationSelection";
import LocationDetails from "./components/LocationDetails";


const MobileViewContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MobileLocationSelectionContainer = styled.div`
  flex: 0 0 auto;
  padding: 16px 10%;
`;

const MobileLocationDetailsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 0 10%;
`;

function LocationsMobileView({currentLocation, changeLocation, locations}) {
  const { setMobileBackButtonVisible, setMobileBackButtonLocation } = useContext(AppStateContext);

  useEffect(() => {
    setMobileBackButtonLocation("/");
    setMobileBackButtonVisible(true);
  }, [setMobileBackButtonLocation, setMobileBackButtonVisible]);

  return (
    <main>
      <MobileViewContainer>
        <MobileLocationSelectionContainer>
          <MobileLocationSelection locations={locations} currentLocation={currentLocation} changeLocation={changeLocation} />
        </MobileLocationSelectionContainer>
        <MobileLocationDetailsContainer>
          <LocationDetails location={currentLocation} />
        </MobileLocationDetailsContainer>
      </MobileViewContainer>
    </main>
  );
}

export default LocationsMobileView;
