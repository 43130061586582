import React from 'react';
import { useMediaQuery } from 'react-responsive'

function BrandingLogo() {

    const navLogoStyle = {
        position: 'absolute',
        minWidth: "165px",
        minHeight: "75px",
        maxWidth: "10%",
        maxHeight: "8%",
        right: '0px',
        top: '0px',
        zIndex: '3000',
        //backdropFilter: 'blur(5px)',
    };

    const navLogoStyleMobile = {
        position: 'absolute',
        minWidth: "110px",
        minHeight: "50px",
        maxWidth: "10%",
        maxHeight: "8%",
        right: '0px',
        top: '0px',
        zIndex: '3000',
        //backdropFilter: 'blur(5px)',
    };

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 916px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 699px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });



    if(isDesktopOrLaptop) {
        return (
            <React.Fragment>
                <img style={navLogoStyle} href="/" src={require('../assets/logo/ha-logo-rgb-eng-margin.png')}></img>
            </React.Fragment>
        );
    }
    else if(isTabletOrMobile) {
        return (
            <React.Fragment>
                <img style={navLogoStyleMobile} href="/" src={require('../assets/logo/ha-logo-rgb-eng-margin.png')}></img>
            </React.Fragment>
        );
    }


}

export default BrandingLogo;