import React from 'react';
import { useMediaQuery } from 'react-responsive'

function Footer() {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 916px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 699px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

    function ToggleImprintModal() {
        //console.log("Showing Impressum modal(add)");
        const _modal = document.getElementById('backdrop-modal-imprint');
        if (_modal) {
            // deactivate modal
            _modal.classList.toggle('active-modal');
        }
        try { window._paq && window._paq.push(['trackEvent', "ImprintModal", 'ModalToggled', 'Imprint']); } catch (e) { }
    }
    function TogglePrivacyModal() {
        //console.log("Showing privacy modal(toggle)");
        const _modal = document.getElementById('backdrop-modal-privacy');
        if (_modal) {
            // deactivate modal
            _modal.classList.toggle('active-modal');
        }
        try { window._paq && window._paq.push(['trackEvent', "PrivacyModal", 'ModalToggled', 'Privacy']); } catch (e) { }
    }

    function ShowConsentlayer() {
        if (window.UC_UI) {
            window.UC_UI.showSecondLayer();
        }
    }


    if (isDesktopOrLaptop) {
        return (
            <React.Fragment>
                <footer style={{ position: 'fixed', bottom: "0px", width: "100%", height: "25px", backgroundColor: "rgba(255,255,255,0.8)" }}>
                    <div style={{ position: 'relative', alignContent: 'center', color: 'orange' }}>
                        <label onClick={() => ToggleImprintModal()} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Imprint
                        </label>
                        <label onClick={() => TogglePrivacyModal()} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Privacy
                        </label>
                        <label onClick={() => { ShowConsentlayer() }} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Cookie-Settings
                        </label>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
    else if (isTabletOrMobile) {
        return (
            <React.Fragment>
                <div style={{ position: 'fixed', bottom: "0px", width: "100%", height: "25px", backgroundColor: "rgba(255,255,255,0.8)" }}>
                    <div style={{ position: 'relative', alignContent: 'center', color: 'orange' }}>
                        <label onClick={() => ToggleImprintModal()} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Imprint
                        </label>
                        <label onClick={() => TogglePrivacyModal()} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Privacy
                        </label>
                        <label onClick={() => { ShowConsentlayer() }} style={{ cursor: 'pointer', fontWeight: 500, marginLeft: "2%", marginRight: "2%" }}>
                            Cookie-Settings
                        </label>
                    </div>
                </div>
            </React.Fragment>
        );
    }


}

export default Footer;