import {useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import TransitionMovie from "./TransitionMovie";

function TransitionMovieContentWrapper({ setupBackground, video, duration, children }) {
  const [searchParams] = useSearchParams();
  const [transitionMoviePlaying, setTransitionMoviePlaying] = useState(searchParams.get('tx') !== "0");

  const showContent = useCallback(() => {
    setTransitionMoviePlaying(false);
  }, [setTransitionMoviePlaying]);

  useEffect(() => {
    const lazyMovie = async () => {
      if (!transitionMoviePlaying) {
        setupBackground();
      } else {
        await new Promise((resolve) => setTimeout(resolve, 700));
        setupBackground();
      }
    };
    lazyMovie();
  }, []);

  if (transitionMoviePlaying) {
    return (
      <TransitionMovie video={video} duration={duration} onFinished={showContent} />
    );
  }
  return (
    <> { children } </>
  );
}

export default TransitionMovieContentWrapper;
