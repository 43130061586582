import LocationsCallouts from "../buttons/locations-callouts.component";
import BackButton from "../buttons/back-button-menu-hj.component";
import DesktopLocationInformationBox from "./components/DesktopLocationInformationBox";
import React, {useMemo, useState} from "react";
import styled from "styled-components";

const DesktopBackButtonContainer = styled.div`
    position: absolute;
    bottom: 5%;
    left: 3%;
`;

function LocationsDesktopView({currentLocation, changeLocation}) {
  const [locationsInformationBoxSize, setLocationsInformationBoxSize] = useState(undefined);
  const calloutMargin = useMemo(() => {
    return { top: 0, right: locationsInformationBoxSize ? locationsInformationBoxSize.width : 0, bottom: 0, left: 0 }
  }, [locationsInformationBoxSize]);

  return (
    <main>
      <LocationsCallouts currentLocationYear={currentLocation.year} clickCallback={changeLocation} margin={calloutMargin} />
      <DesktopBackButtonContainer>
        <BackButton navigateTo="/" transition="0" />
      </DesktopBackButtonContainer>
      <DesktopLocationInformationBox location={currentLocation} onSizeChanged={setLocationsInformationBoxSize} />
    </main>
  );
}

export default LocationsDesktopView;
