import React, { useCallback } from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import { useTechnologies } from "../custom-hooks/useTechnologies";
import { useTechnologyBy } from "../custom-hooks/useTechnologyBy";
import LoadingScreen from "../components/container/LoadingScreen";
import EngineeringDevelopmentRoomView from "../components/technology/engineering-development/EngineeringDevelopmentRoomView"
function TechnologyEDEcoDesignRoom({ changeBackgroundImage, changeLayeredMovies }) {
    const technologies = useTechnologies();
    const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "ECOD"); //TODO: add here xyz

    const setupBackground = useCallback(() => {
        changeBackgroundImage("empty_page.png");
        changeLayeredMovies(["Loop_Technologie_Pressure-Die-Casting.webm"]); //TODO: change here xyz
    }, [changeBackgroundImage, changeLayeredMovies]);

    return (
        <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_Technologie_Pressure-Die-Casting_[00000-00068].webm" duration={2200}>
            {!technology && <LoadingScreen />}
            {technology && <EngineeringDevelopmentRoomView technology={technology} />}
        </TransitionMovieContentWrapper>
    );
}

export default TechnologyEDEcoDesignRoom;
