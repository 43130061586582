import '../../App.css';
import './responsive-test.css';
import Delayed from '../../misc/Delayed';
//import CalloutExternalButton from '../buttons/callout-external.button';
//import CalloutButton from '../buttons/callout.button.component';
import ProductsCalloutButton from '../buttons/p-callout.button.component';


/*
*   Die Verschachtelung der Components kommt daher dass die Buttons(waren früher ganz anderse undwir haben Callouts gesagt)
*   anderes funktioniert haben.
*/
function ResponsiveCallouts(props) {
    return (
        <div className="globalContainer" id="global_container">
            <div id="#buttongroup">
                <Delayed waitBeforeShow={300}>
                    <ProductsCalloutButton />
                </Delayed>
            </div>
        </div>
    );
}

export default ResponsiveCallouts;
