import BackdropModalVideo from "../../container/BackdropModalVideo";
import Delayed from "../../../misc/Delayed";
import ProductsCarouselImage from "../../container/ProductsCarouselImage";
import BackButtonProducts from "../../buttons/back-button-products-menu-hj.component";
import React, {useCallback, useState} from "react";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import ProductDetails from "./components/product-details";
import ProductSelection from "./components/product-selection";

const MobileViewContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MobileProductSelection = styled.div`
  flex: 0 0 auto;
  padding: 16px 10%;
`;

const DesktopProductSelection = styled.div`
    position: absolute;
    bottom: 5%;
    left: 3%;
    width: 450px;
`;

const MobileProductDetails = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 0 10%;
`;

const DesktopProductDetails = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 0.8);
    height: 100%;
    width: 36%;
    max-width: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 50px 4% 0 4%;
    text-align: left;
`;

function ProductDetailsView({products, currentProduct, category, selectProduct, resetProduct}) {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 700px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;

  const [showVideoOverlay, setShowVideoOverlay] = useState(false);
  const [videoOverlaySource, setVideoOverlaySource] = useState("");
  const [videoOverlayTitle, setVideoOverlayTitle] = useState("");

  const OnVideoOverlayOpened = useCallback((src, title) => {
    setVideoOverlaySource(src);
    setVideoOverlayTitle(title);
    setShowVideoOverlay(true);
  }, [setVideoOverlaySource, setVideoOverlayTitle, setShowVideoOverlay]);

  const OnVideoLinkClicked = useCallback((src, heading) => {
    OnVideoOverlayOpened(src, heading);
  }, [OnVideoOverlayOpened]);

  const OnVideoOverlayClosed = useCallback(() => {
    setShowVideoOverlay(false);
  }, [setShowVideoOverlay]);

  return (
    <>
      { showVideoOverlay && <BackdropModalVideo title={videoOverlayTitle} source={videoOverlaySource} handleCloseVideoOverlay={OnVideoOverlayClosed} /> }
      <Delayed waitBeforeShow={100}>
        <ProductsCarouselImage productCarouselPodest={currentProduct.productFiles.productCarouselPodest}
                               lastProductCarouselImage={currentProduct.productFiles.productCarouselImage}
                               productCarouselImage={currentProduct.productFiles.productCarouselImage} />
      </Delayed>
      {
        isDesktopView && <>
          <DesktopProductDetails>
            <ProductDetails product={currentProduct.informationBox} category={category} onVideoLinkClicked={OnVideoLinkClicked} />
          </DesktopProductDetails>
          <DesktopProductSelection>
            <ProductSelection category={category}
                              products={products}
                              currentProduct={currentProduct}
                              selectProduct={selectProduct} />
            <div style={{marginTop: "3%"}} onClick={resetProduct} role="button"> <BackButtonProducts /> </div>
          </DesktopProductSelection>
        </>
      }
      {
        !isDesktopView && <>
          <MobileViewContainer>
            <MobileProductSelection>
              <ProductSelection category={category}
                                products={products}
                                currentProduct={currentProduct}
                                selectProduct={selectProduct} />
            </MobileProductSelection>
            <MobileProductDetails>
              <ProductDetails product={currentProduct.informationBox} category={category} onVideoLinkClicked={OnVideoLinkClicked} />
            </MobileProductDetails>
          </MobileViewContainer>
        </>
      }
    </>
  );
}

export default ProductDetailsView;
