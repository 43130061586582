import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TechnologiesCallouts from "../components/buttons/technologies-callouts.component";
import TransitionMovie from '../components/container/TransitionMovie';
import '../components/buttons/hj-callouts.css';
import LoadingScreen from "../components/container/LoadingScreen";

const Technologies = ({ changeLayeredMovies, changeBackgroundImage, isBackgroundReady }) => {
  const [searchParams] = useSearchParams();
  const transition = searchParams.get('tx');

  const [backgroundChanged, setBackgroundChanged] = useState(false);
  const [transitionRunning, setTransitionRunning] = useState(transition !== "0");


  const onTransitionFinished = useCallback(() => {
    setTransitionRunning(false);
  }, [setTransitionRunning]);


  useEffect(() => {
    const lazyMovie = async () => {
      if (transition === "0") {
        changeBackgroundImage("empty_page.png");
        changeLayeredMovies(["Loop_Technologie_noAlpha[00000-00249].webm"]); //(["Loop_Technologie_Windraeder-Fluss_[00000-00250].webm"]);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 700));
        changeBackgroundImage("empty_page.png");
        changeLayeredMovies(["Loop_Technologie_noAlpha[00000-00249].webm"]); //(["Loop_Technologie_Windraeder-Fluss_[00000-00250].webm"]);
      }
      setBackgroundChanged(true);
    };
    lazyMovie();
  }, []);

  useEffect(() => {
    //window._mtm.push({ 'event': 'mtm.PageView' });
  }, []);


  if (transitionRunning) {
    return (
      <main>
        <TransitionMovie video="Anflug_Technologie_[00000-00100].webm" onFinished={onTransitionFinished} duration={3900} />
      </main>
    );
  }
  if (backgroundChanged && isBackgroundReady) {
    return (
      <main>
        <TechnologiesCallouts />
      </main>
    );
  }
  return (
    <LoadingScreen />
  );
}

export default Technologies;
