import '../../App.css';
import { Link } from 'react-router-dom';
import './responsive-callouts.css';
import './hj-callouts.css';
import BackButton from './back-button-menu-hj.component';
import { useWindowSize } from "../../custom-hooks/useWindowSize";
import { useContext, useEffect, useMemo } from "react";
import AppStateContext from "../../contexts/appStateContext";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

function GetVideoScaling() {
    const bgVideos = document.getElementsByClassName('background-video-redesign');
    if (bgVideos.length > 0) {
        let bgVideoScaling = {};
        const videoBackground = bgVideos[0];
        bgVideoScaling.x = videoBackground.clientWidth;
        bgVideoScaling.y = videoBackground.clientHeight;
        return bgVideoScaling;
    } else {
        const bgImage = document.getElementsByClassName('background-layer-still')[0];
        let bgImageScaling = {};
        bgImageScaling.x = bgImage.clientWidth;
        bgImageScaling.y = bgImage.clientHeight;
        return bgImageScaling;
    }
}

function computeContainerPosition(windowWidth, windowHeight) {
    const absoluteBackgroundSize = GetVideoScaling();

    let theSizeXRight = windowWidth; // for buttons with relative value > 0.5!
    let theSizeXLeft = windowWidth;
    let theSizeY = absoluteBackgroundSize.y;
    if (absoluteBackgroundSize.x > windowWidth) {
        let diff = absoluteBackgroundSize.x - windowWidth;
        let h_diff = diff / 4; // its /4 because i scale on BOTH ends into the image, therefore i only add a quarter of the diff
        theSizeXRight += h_diff;
        theSizeXLeft -= h_diff;
    }

    // CONFIG
    const SIDES_MARGIN_VALUE = 75;
    const BUTTON_WIDTH_VALUE = 400;

    // Position of Container
    let cValue = (theSizeXRight * 0.550);

    // Keep distance on the right
    let colValue_c = cValue + BUTTON_WIDTH_VALUE;
    if (colValue_c >= windowWidth) {
        cValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }

    // Keep distance on the left
    cValue = cValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : cValue;

    // Set values
    let TopMargin = 180;
    if (windowHeight < 700) {
        TopMargin = 105;
    }
    return {
        left: (cValue + "px"),
        top: ("" + TopMargin + "px"),
    };
}

function togglePopup() {
    const popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
}


function TechnologiesCallouts() {
    const { setMobileBackButtonVisible, setMobileBackButtonLocation } = useContext(AppStateContext);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isDesktopView = isDesktopOrLaptop && !isPortrait;

    useEffect(() => {
        setMobileBackButtonLocation("/");
        setMobileBackButtonVisible(!isDesktopView);
    }, [isDesktopView]);

    const windowSize = useWindowSize();
    const containerPosition = useMemo(
        () => computeContainerPosition(windowSize.width, windowSize.height),
        [windowSize.width, windowSize.height]
    );

    const containerStyle = useMemo(() => {
        if (isDesktopView) {
            return {
                left: containerPosition.left,
                top: containerPosition.top,
            };
        }
        return {};
    }, [isDesktopView, containerPosition]);
    const containerClassNames = classNames("hj-technology-callout-container", { "hj-technology-callout-container--desktop": isDesktopView });

    return (
        <div className='fade-in-button'>
            <div className={containerClassNames} style={containerStyle}>
                <div className='hj-technology-heading'>ENGINEERING & DEVELOPMENT</div>
                {/*
                <div className='hj-callout-technology-wrapper popup' onClick={togglePopup}>
                    <span className='popuptext' id='myPopup'>coming soon!</span>
                    <span className='hj-callout-products-content'>ENGINEERING AND DEVELOPMENT</span>
    </div>*/}
                <Link to={"/competences/rapid-prototyping"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>RAPID PROTOTYPING</span>
                    </div>
                </Link>
                <Link to={"/competences/optimisation"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>OPTIMISATION</span>
                    </div>
                </Link>
                <Link to={"/competences/simulations"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>SIMULATIONS</span>
                    </div>
                </Link>
                <br />
                <div className='hj-technology-heading'>SYSTEMS ENGINEERING</div>
                {/*<Link to={"/competences/engineering"}>
                        <div className='hj-callout-technology-wrapper'>
                            <span className='hj-callout-products-content'>SYSTEMS ENGINEERING</span>
                        </div>
    </Link>*/}
                <Link to={"/competences/battery-assembly-systems-engineering"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>BATTERY ASSEMBLY</span>
                    </div>
                </Link>
                <Link to={"/competences/battery-systems"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>BATTERY SYSTEMS</span>
                    </div>
                </Link>
                <br />
                <div className='hj-technology-heading'>LIGHT METAL CASTING</div>
                <Link to={"/competences/mega-casting"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>MEGA-CASTING</span>
                    </div>
                </Link>
                <Link to={"/competences/high-pressure-die-casting"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>HIGH-PRESSURE DIE CASTING</span>
                    </div>
                </Link>
                <Link to={"/competences/gravity-die-casting"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>GRAVITY DIE CASTING</span>
                    </div>
                </Link>
                <Link to={"/competences/machining-and-assembling"}>
                    <div className='hj-callout-technology-wrapper'>
                        <span className='hj-callout-products-content'>ASSEMBLY</span>
                    </div>
                </Link>
                {isDesktopView && <BackButton transition="0" navigateTo="/" />}
            </div>
        </div>
    );
}

export default TechnologiesCallouts;



/*
function TechnologiesCallouts(props) {
    return (
        <>
            <div className='fade-in-button'>
                <img className='assembly-systems-engineering-heading' src={require('../../assets/callouts/Systems_Engineering.png')}></img>
                <Link to={"/competences/assembly-systems-engineering"}>
                    <div className='callout-button-container assembly-systems-engineering'>
                        <div className='callout-text-container'>
                            <img className='callout-image-ase' src={require('../../assets/callouts/Assembly_Systems_Engineering.png')}></img>
                        </div>
                    </div>
                </Link>
                <Link to={"/competences/engineering"}>
                    <div className='callout-button-container engineering'>
                        <div className='callout-text-container'>
                            <img className='callout-image-engineering' src={require('../../assets/callouts/Engineering.png')}></img>
                        </div>
                    </div>
                </Link>
                <img className='metal-casting-heading' src={require('../../assets/callouts/LIGHT-METAL-CASTING.png')}></img>
                <Link to={"/competences/high-pressure-die-casting"}>
                    <div className='callout-button-container high-pressure-die-casting'>
                        <div className='callout-text-container'>
                            <img className='callout-image-hpdc' src={require('../../assets/callouts/xCallout_line-hpdc.png')}></img>
                        </div>
                    </div>
                </Link>
                <Link to={"/competences/gravity-die-casting"}>
                    <div className='callout-button-container gravity-die-casting'>
                        <div className='callout-text-container'>
                            <img className='callout-image-gdc' src={require('../../assets/callouts/xCallout_line-gdc.png')}></img>
                        </div>
                    </div>
                </Link>
                <Link to={"/competences/machining-and-assembling"}>
                    <div className='callout-button-container assembly'>
                        <div className='callout-text-container'>
                            <img className='callout-image-asm' src={require('../../assets/callouts/xCallout_line-asm.png')}></img>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}
*/
