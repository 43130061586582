import React, { useState, useCallback } from "react";
import { BsFilm, BsLink45Deg } from "react-icons/bs";
import HtmlParser from "../../../misc/HtmlParser";
import styled from "styled-components";
import BackdropModalVideo from "../../container/BackdropModalVideo";

const Heading = styled.div`
    font-size: calc(14pt + 0.15vw);
    margin-bottom: 3%;
    margin-top: max(40px, 10%);
`;

const Subheading = styled.div`
    font-size: calc(20pt + 0.3vw);
    margin-bottom: 5%;
`;

const Text = styled.div`
    font-size: max(16px, calc(3pt + 0.5vw));
`;

const ContentLabel = styled.div`
  fontWeight: bold;
  color: black; 
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: var(--main-handtmann-color);
`;

const StyledLinkVideo = styled.span`
    text-decoration: none;
    color: var(--main-handtmann-color);
    cursor: pointer;
`;

function TechnologyInformationDetails({ technology, openVideoOverlay }) {
  //console.log("techy link:", technology.videoLink)
  return (
    <>
      <Heading>COMPETENCES</Heading>
      <Subheading>{technology.technologyName}</Subheading>
      <Text>
        <HtmlParser string={technology.technologyInformationBox.description} />
        <p />
        <br /><br />
        <ContentLabel>CONTENT</ContentLabel>
        <br />
        <div onClick={() => { window._paq && window._paq.push(['trackEvent', "CompetencesInfobox", 'InfoboxLinkClicked', technology.homepageLink]); }}>
          <StyledLink target="_blank" rel="noreferrer" href={technology.homepageLink}>
            <BsLink45Deg style={{ position: 'relative', bottom: '0.0625em' }} /> Homepage
          </StyledLink>
        </div>
        {technology.videoLink && <div id="infobox-video" onClick={() => { window._paq && window._paq.push(['trackEvent', "CompetencesInfobox", 'InfoboxVideoClicked', technology.videoLink]); openVideoOverlay() }}>
          <StyledLinkVideo target="_blank" rel="noreferrer" >
            <BsFilm style={{ position: 'relative', bottom: '0.0625em' }} /> Video
          </StyledLinkVideo>
        </div>}
      </Text>
    </>
  );
}

export default TechnologyInformationDetails;
