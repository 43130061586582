import {useEffect, useState} from "react";
import {default as axios} from "axios";
import {listProductsQueryConfig} from "../gql";

function filterProductsByCategory(products, category) {
  let filteredProducts = [];
  products.forEach(product => { //array.find verwenden? TODO:
    // eslint-disable-next-line
    if (product.category === category) {
      filteredProducts.push(product);
    }
  });
  return filteredProducts;
}

export function useProductsFilteredByCategory(category) {
  const [products, setProducts] = useState(null);
  useEffect(() => {

    axios.get("https://gi021a7w4j.execute-api.eu-central-1.amazonaws.com/products")
    .then((response) => {
      const products = response.data;
      //console.log("pagw", response.data)
      const filteredProducts = filterProductsByCategory(products, category);
      setProducts(filteredProducts);
    })
    .catch((error) => {console.log(error)})

    /*axios(listProductsQueryConfig)
      .then((response) => {
        const products = response.data.data.listProducts.data;
        //console.log("pgql:", products)
        const filteredProducts = filterProductsByCategory(products, category);
        setProducts(filteredProducts);
      }).catch(function (error) {
      console.log(error);
    });*/
  }, [category]);
  return products;
}
