import { useEffect, useState } from "react";
import { default as axios } from "axios";
import { listTechnologiesQueryConfig } from "../gql";

export function useTechnologies(filter) {
  const [technologies, setTechnologies] = useState(null);
  useEffect(() => {

    axios.get("https://gi021a7w4j.execute-api.eu-central-1.amazonaws.com/technologies")
      .then((response) => {
        const technologies = response.data;
        if (filter) {
          technologies = technologies.filter(filter);
        }
        setTechnologies(technologies);
      })
      .catch((error) => { console.log(error) })




    /*axios(listTechnologiesQueryConfig)
      .then((response) => {
        let technologies = response.data.data.listTechnologies.data;
        if (filter) {
          technologies = technologies.filter(filter);
        }
        setTechnologies(technologies);
      }).catch(function (error) {
        console.log(error);
      });*/
  }, [filter]);
  return technologies;
}
