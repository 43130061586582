import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FaSearch} from 'react-icons/fa';
import {BsX} from 'react-icons/bs';
import Fuse from 'fuse.js';
import {useLocation} from 'react-router-dom';
import {useProducts} from "../../../custom-hooks/useProducts";
import styled from "styled-components";
import SearchbarResultList from "./searchbar-result-list";

const SearchContainer = styled.div`
    position: relative;
    margin: 0 15px;
    padding: 0 15px;
    border-left: solid;
    border-right: solid;
    border-width: 2px;
    color: rgb(200,200,200);
    display: flex;
    align-items: center;
    min-width: 0;
`;

const SearchIcon = styled(FaSearch)`
    margin: 0 10px 0 0;
    flex: 0 0 auto;
`;

const CloseIcon = styled(BsX)`
    color: rgb(100,100,100);
    flex: 0 0 auto;
`;

const SearchInput = styled.input`
    position: relative;
    width: 260px;
    min-width: 0;
    border: none;
    outline: none;
    flex: 1 1 auto;
    
    ::placeholder {
        color: rgba(160, 160, 160, 0.95);
        font-size: large;
    }
`;

const SearchResultListContainer = styled.div`
    background: white;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    min-height: 40px;
    max-height: 20vh;
    overflow: auto;
`;


function SearchBar({...props}) {
    const location = useLocation();
    const allProducts = useProducts((product) => product.category !== "not-assigned");

    const [productSearchString, setProductSearchString] = useState("");
    const [isResultListVisible, setIsResultListVisible] = useState(false);

    const fuse = useMemo(() => {
        //console.log("Searching")
        return new Fuse(allProducts || [], {
            keys: ['productName', 'productId', 'searchKeywords']
        });
    }, [allProducts]);

    const onSearchInputChange = useCallback((event) => {
        //console.log("search input change");
        setProductSearchString(event.target.value);
        if (event.target.value.length >= 3) {
            setIsResultListVisible(true);
        } else {
            setIsResultListVisible(false);
        }
    }, [setProductSearchString, setIsResultListVisible]);

    const clearSearch = useCallback(() => {
        //console.log("clearing search")
        setProductSearchString("");
        setIsResultListVisible(false);
    }, [setProductSearchString]);

    const searchResults = useMemo(() => {
        //console.log("useMemo searchResults");
        // suche dürfte wie folgt funktionieren:
        // Zuerst habe ich das Array mit allen Produkten, und deren KEYWORDS
        // Jetzt SUCHE ich nach dem NAMEN UND DEN KEYWORDS
        // und zeige den NAMEN an
        // im ersten Schritt reicht wenn ich den eingebenen string /wort für wort/ mit "includes" der namen und keywords vergleiche
        // wenn dann was zurück kommt dann ist es ein trefer und wird demm "results" array hinzugefügt, aber eben NIE zweimal!
        return productSearchString ? fuse.search(productSearchString) : [];
    }, [fuse, productSearchString]);

    useEffect(() => {
        clearSearch();
    }, [location]);


    return (
      <SearchContainer {...props}>
          <SearchIcon />
          <SearchInput type="text"
                       name="search-bar"
                       placeholder="What are you looking for today?"
                       value={productSearchString}
                       onChange={onSearchInputChange} />
          <CloseIcon onClick={clearSearch} />
          { isResultListVisible && <SearchResultListContainer>
              <SearchbarResultList searchResults={searchResults} searchValue={productSearchString} />
          </SearchResultListContainer> }
      </SearchContainer>
    );
}

export default SearchBar;
