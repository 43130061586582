import '../../App.css';
import './responsive-callouts.css';
import { useMemo } from 'react';
import { useWindowSize } from "../../custom-hooks/useWindowSize";


function GetBackgroundScaling() {
    const bgVideos = document.getElementsByClassName('background-layer-still');
    let bgVideoScaling = {};
    const videoBackground = bgVideos[0];
    bgVideoScaling.x = videoBackground.clientWidth;
    bgVideoScaling.y = videoBackground.clientHeight;
    return bgVideoScaling;
}

function computeButtonPositions(windowWidth, windowHeight, margin) {
    const absoluteBackgroundSize = GetBackgroundScaling();

    const viewportBoundaries = {
        top: margin ? margin.top : 0,
        right: margin ? windowWidth - margin.right : 0,
        bottom: margin ? windowHeight - margin.bottom : 0,
        left: margin ? margin.left : 0
    };

    // calculate position:
    // KISS: Schauen, wie groß das Bild ist und davon über den relativen Faktor einen absoluten Wert berechnen
    //console.log(absoluteBackgroundSize.x);
    let theSizeXRight = windowWidth; // for buttons with relative value > 0.5!
    let theSizeXLeft = windowWidth;
    let theSizeY = absoluteBackgroundSize.y;


    if (absoluteBackgroundSize.x > windowWidth) {
        let diff = absoluteBackgroundSize.x - windowWidth;
        let h_diff = diff / 4; // its /4 because i scale on BOTH ends into the image, therefore i only add a quarter of the diff
        theSizeXRight += h_diff;
        theSizeXLeft -= h_diff;
    }

    const SIDES_MARGIN_VALUE = 35;
    const BUTTON_WIDTH_VALUE = 192;

    // Get the base Values(not with collision in mind yet)
    let tValue = (theSizeXRight * 0.580); // tianjin
    let kValue = (theSizeXLeft * 0.435); // kosice
    let nValue = (theSizeXLeft * 0.080); // north america
    let bValue = (theSizeXLeft * 0.285); // biberach
    let aValue = (theSizeXLeft * 0.285); // annaberg

    // colliding from the right: Products and AboutUs
    let colValue_p = tValue + BUTTON_WIDTH_VALUE;
    if (colValue_p >= windowWidth) {
        tValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }
    let colValue_a = aValue + BUTTON_WIDTH_VALUE;
    if (colValue_a >= windowWidth) {
        //aValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }
    // colliding from the left: Sustainability, competences and Locations but fix ALL at left "SIDES_MARGIN_VALUE" anyway
    tValue = Math.max(SIDES_MARGIN_VALUE, Math.min(tValue, viewportBoundaries.right - BUTTON_WIDTH_VALUE));
    aValue = aValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : aValue;
    nValue = nValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : nValue;
    kValue = kValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : kValue;
    bValue = bValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : bValue;

    return {
        tianjin: {
            left: tValue + "px",
            top: (theSizeY * 0.365) + "px",
        },
        kosice: {
            left: kValue + "px",
            top: (theSizeY * 0.320) + "px",
        },
        northAmerica: {
            left: nValue + "px",
            top: (theSizeY * 0.410) + "px",
        },
        biberach: {
            left: bValue + "px",
            top: (theSizeY * 0.230) + "px",
        },
        annaberg: {
            left: aValue + "px",
            top: (theSizeY * 0.275) + "px",
        },
    };
}

function LocationsCallouts({ currentLocationYear, clickCallback, margin }) {
    const windowSize = useWindowSize();
    const buttonPositions = useMemo(
        () => computeButtonPositions(windowSize.width, windowSize.height, margin),
        [windowSize.width, windowSize.height, margin]
    );

    return (
        <>
            <div className='fade-in-button redesign-landingpage-buttons-container'>
                <div className='redesign-landingpage-products-button-wrapper' style={{ top: buttonPositions.tianjin.top, left: buttonPositions.tianjin.left }}>
                    <div className={currentLocationYear === 2030 ? 'redesign-landingpage-products-button' : 'redesign-landingpage-products-button inactive-color'} onClick={() => { window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', "TIANJIN"]); clickCallback(2030) }}>
                        <div className='redesign-button'>
                            TIANJIN
                        </div>
                    </div>
                </div>
                <div className='redesign-landingpage-products-button-wrapper' style={{ top: buttonPositions.biberach.top, left: buttonPositions.biberach.left }}>
                    <div className={currentLocationYear === 2021 ? 'redesign-landingpage-products-button' : 'redesign-landingpage-products-button inactive-color'} onClick={() => { window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', "BIBERACH"]); clickCallback(2021) }}>
                        <div className='redesign-button'>
                            BIBERACH
                        </div>
                    </div>
                </div>
                <div className='redesign-landingpage-products-button-wrapper' style={{ top: buttonPositions.northAmerica.top, left: buttonPositions.northAmerica.left }}>
                    <div className={currentLocationYear === 2023 ? 'redesign-landingpage-products-button' : 'redesign-landingpage-products-button inactive-color'} onClick={() => { window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', "NORTH AMERICA"]); clickCallback(2023) }}>
                        <div className='redesign-button'>
                            NORTH AMERICA
                        </div>
                    </div>
                </div>
                <div className='redesign-landingpage-products-button-wrapper' style={{ top: buttonPositions.annaberg.top, left: buttonPositions.annaberg.left }}>
                    <div className={currentLocationYear === 2024 ? 'redesign-landingpage-products-button' : 'redesign-landingpage-products-button inactive-color'} onClick={() => { window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', "ANNABERG"]); clickCallback(2024) }}>
                        <div className='redesign-button' >
                            ANNABERG
                        </div>
                    </div>
                </div>
                <div className='redesign-landingpage-products-button-wrapper' style={{ top: buttonPositions.kosice.top, left: buttonPositions.kosice.left }}>
                    <div className={currentLocationYear === 2025 ? 'redesign-landingpage-products-button' : 'redesign-landingpage-products-button inactive-color'} onClick={() => { window._paq && window._paq.push(['trackEvent', "Locations", 'LocationClicked', "KOŠICE / KECHNEC"]); clickCallback(2025) }}>
                        <div className='redesign-button'>
                            KOŠICE / KECHNEC
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <div className='fade-in-button'>
                <div className='callout-button-container biberach'>
                    <div className='hj-callout-landingpage-container xcallout-text-container'>
                        <div className='hj-callout-landingpage-wrapper' onClick={() => props.clickCallback(2021)}>
                            <div className='hj-callout-landingpage-content'>
                                BIBERACH
                            </div>
                        </div>
                    </div>
                </div>
                <div className='callout-button-container texas'>
                    <div className='hj-callout-landingpage-container xcallout-text-container'>
                        <div className='hj-callout-landingpage-wrapper' onClick={() => props.clickCallback(2023)}>
                            <div className='hj-callout-landingpage-content'>
                                NORTH AMERICA
                            </div>
                        </div>
                    </div>
                </div>
                <div className='callout-button-container kechnec'>
                    <div className='hj-callout-landingpage-container xcallout-text-container'>
                        <div className='hj-callout-landingpage-wrapper' onClick={() => props.clickCallback(2025)}>
                            <div className='hj-callout-landingpage-content'>
                                KOŠICE / KECHNEC
                            </div>
                        </div>
                    </div>
                </div>
                <div className='callout-button-container tianjin'>
                    <div className='hj-callout-landingpage-container xcallout-text-container'>
                        <div className='hj-callout-landingpage-wrapper' onClick={() => props.clickCallback(2030)}>
                            <div className='hj-callout-landingpage-content'>
                                TIANJIN
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    */}
        </>
    );
}

export default LocationsCallouts;
