import styled from "styled-components";
import BackButton from "../../buttons/back-button-menu-hj.component";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SystemsEngineeringTechnologySelection from "./components/SystemsEngineeringTechnologySelection";
import BackdropModalVideo from "../../container/BackdropModalVideo";
import AppStateContext from "../../../contexts/appStateContext";
import TechnologyInformationBox from "../components/TechnologyInformationBox";
import TechnologyInformationDetails from "../components/TechnologyInformationDetails";

const DesktopClickZone = styled.div`
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 50vw;
  height: 55vh;
`;

const DesktopSelectionContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 3%;
  width: 325px;
`;

function DesktopView({ technology, openVideoOverlay }) {
  return (
    <>
      <DesktopSelectionContainer>
        <SystemsEngineeringTechnologySelection />
        <BackButton navigateTo="/competences" transition="0" />
      </DesktopSelectionContainer>
      {technology.technologyShortName === "ASE" && <DesktopClickZone onClick={openVideoOverlay} />}
      {technology.technologyShortName !== "ASE" && <TechnologyInformationBox technology={technology} openVideoOverlay={openVideoOverlay} />}
    </>
  );
}

// const MobileClickZone = styled.div`
//   position: absolute;
//   top: 21.5vh;
//   left: 50%;
//   transform: translateX(-50%);
//   cursor: pointer;
//   width: 100vmin;
//   max-width: 700px;
//   max-height: 100vh;
//   aspect-ratio: 16 / 9;
// `;

const MobileClickZone = styled.div`
  flex: 1 0 0;
`;

const MobileViewContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MobileLocationSelectionContainer = styled.div`
  flex: 0 0 auto;
  padding: 16px 10%;
`;

const MobileLocationDetailsContainer = styled.div`
  flex: 1 0 0;
  display: flex;
  //overflow: auto;
  //background-color: rgba(255, 255, 255, 0.8);
  //text-align: left;
  //padding: 15px 10%;
`;
const VisibleMobileLocationDetailsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 15px 10%;
`;

function MobileView({ technology, openVideoOverlay, changeBackgroundImageStyleOverride }) {
  const { setMobileBackButtonVisible, setMobileBackButtonLocation } = useContext(AppStateContext);
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });


  useEffect(() => {
    changeBackgroundImageStyleOverride(isPortrait ? { objectPosition: "-6.4vh 4.5vh" } : { height: "165vh", width: "auto" });
    return () => changeBackgroundImageStyleOverride(null);
  }, [isPortrait]);

  useEffect(() => {
    setMobileBackButtonLocation("/competences");
    setMobileBackButtonVisible(true);
  }, [setMobileBackButtonLocation, setMobileBackButtonVisible]);


  return (
    <MobileViewContainer>
      <MobileLocationSelectionContainer>
        <SystemsEngineeringTechnologySelection />
      </MobileLocationSelectionContainer>
      {technology.technologyShortName === "ASE" &&
        <MobileLocationDetailsContainer>
          {technology.technologyShortName === "ASE" && <MobileClickZone onClick={openVideoOverlay} />}
        </MobileLocationDetailsContainer>
      }
      {technology.technologyShortName === "BAT" &&
        <VisibleMobileLocationDetailsContainer>
          <TechnologyInformationDetails technology={technology} openVideoOverlay={openVideoOverlay} />
        </VisibleMobileLocationDetailsContainer>
      }

    </MobileViewContainer>
  );
}

function SystemsEngineeringRoomView({ technology, changeBackgroundImageStyleOverride }) {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 700px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;


  const [showVideoOverlay, setShowVideoOverlay] = useState(false);

  const closeVideoOverlay = useCallback(() => {
    setShowVideoOverlay(false);
  }, [setShowVideoOverlay]);

  const openVideoOverlay = useCallback(() => {
    setShowVideoOverlay(true);
  }, [setShowVideoOverlay]);

  return (
    <main>
      {isDesktopView && <DesktopView technology={technology} openVideoOverlay={openVideoOverlay} />}
      {!isDesktopView && <MobileView technology={technology} openVideoOverlay={openVideoOverlay} changeBackgroundImageStyleOverride={changeBackgroundImageStyleOverride} />}
      {
        showVideoOverlay &&
        <BackdropModalVideo title={"Systems Engineering"}
          source={technology.videoLink}
          handleCloseVideoOverlay={closeVideoOverlay} />
      }
    </main>
  );
}

export default SystemsEngineeringRoomView;
