import EngDevelopmentTechnologySelection from "./EngDevelopmentTechnologySelection";
import styled from "styled-components";
import BackButton from "../../buttons/back-button-menu-hj.component";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useMediaQuery } from "react-responsive";
import TechnologyInformationBox from "../components/TechnologyInformationBox";
import TechnologyInformationDetails from "../components/TechnologyInformationDetails";
import AppStateContext from "../../../contexts/appStateContext";
import BackdropModalVideo from "../../container/BackdropModalVideo";
const MobileClickZone = styled.div`
  flex: 1 0 0;
`;
const DesktopSelectionContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 3%;
  width: 325px;
`;

const DesktopClickZone = styled.div`
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 50vw;
  height: 55vh;
`;

function DesktopView({ technology, openVideoOverlay = { openVideoOverlay } }) {
    return (
        <>
            <DesktopSelectionContainer>
                <EngDevelopmentTechnologySelection />
                <BackButton navigateTo="/competences" transition="0" />
            </DesktopSelectionContainer>
            {technology.technologyShortName === "SIMU" && <DesktopClickZone onClick={openVideoOverlay} />}
            {technology.technologyShortName !== "XXXXf" && <TechnologyInformationBox technology={technology} openVideoOverlay={openVideoOverlay} />}
        </>
    );
}

const MobileViewContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MobileLocationSelectionContainer = styled.div`
  flex: 0 0 auto;
  padding: 16px 10%;
`;

const MobileLocationDetailsContainer = styled.div`
    flex: 1 0 0;
    display: flex;
    //overflow: auto;
    //background-color: rgba(255, 255, 255, 0.8);
    //text-align: left;
    //padding: 15px 10%;
`;
const VisibleMobileLocationDetailsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 15px 10%;
`;

function MobileView({ technology, openVideoOverlay = { openVideoOverlay } }) {
    const { setMobileBackButtonVisible, setMobileBackButtonLocation } = useContext(AppStateContext);

    useEffect(() => {
        setMobileBackButtonLocation("/competences");
        setMobileBackButtonVisible(true);
    }, [setMobileBackButtonLocation, setMobileBackButtonVisible]);

    return (
        <MobileViewContainer>
            <MobileLocationSelectionContainer>
                <EngDevelopmentTechnologySelection />
            </MobileLocationSelectionContainer>
            {technology.technologyShortName !== "SIMU" &&
                <VisibleMobileLocationDetailsContainer>
                    <TechnologyInformationDetails technology={technology} openVideoOverlay={openVideoOverlay} />
                </VisibleMobileLocationDetailsContainer>
            }
            {technology.technologyShortName === "SIMU" &&
                <MobileLocationDetailsContainer>
                    {technology.technologyShortName === "SIMU" && <MobileClickZone onClick={openVideoOverlay} />}
                </MobileLocationDetailsContainer>
            }
        </MobileViewContainer>
    );
}

function EngDevelopmentRoomView({ technology }) {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 700px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isDesktopView = isDesktopOrLaptop && !isPortrait;

    const [showVideoOverlay, setShowVideoOverlay] = useState(false);

    const closeVideoOverlay = useCallback(() => {
        setShowVideoOverlay(false);
    }, [setShowVideoOverlay]);

    const openVideoOverlay = useCallback(() => {
        console.log("open video overlay clicked!")
        setShowVideoOverlay(true);
    }, [setShowVideoOverlay]);

    return (
        <main>
            {isDesktopView && <DesktopView technology={technology} openVideoOverlay={openVideoOverlay} />}
            {!isDesktopView && <MobileView technology={technology} openVideoOverlay={openVideoOverlay} />}
            {
                showVideoOverlay &&
                <BackdropModalVideo title={"Systems Engineering"}
                    source={technology.videoLink}
                    handleCloseVideoOverlay={closeVideoOverlay} />
            }
        </main>
    );
}

export default EngDevelopmentRoomView;
