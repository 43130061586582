import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MatomoTracker from './matomo/MatomoTracker'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <>
      {/*
        <OSRNavbar ></OSRNavbar>
*/}
      
      <App _language={(window.location.pathname + "")}></App>
      <MatomoTracker siteId="1" matomoUrl="https://handtmann-analytics.creapolis.de" />
      {/*
      <BrowserRouter>
        <div id="background-content-container">
          <BackgroundContext.Provider value={BackgroundContent.backgroundMovie}>
            <BackgroundMovie video={BackgroundContent.backgroundMovie}></BackgroundMovie>
          </BackgroundContext.Provider>
        </div>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="products" element={<ProductsOverview />} />
          <Route path="products/electric-mobility" element={<ProductsMobility />} />
          <Route path="technologies" element={<Technologies />} />
          <Route path="technologies/high-pressure-die-casting" element={<TechnologyHPCasting />} />
          <Route path="technologies/gravity-die-casting" element={<TechnologyGravityDieCasting />} />
          <Route path="locations" element={<Locations />} />
          <Route path="sustainability" element={<Sustainability />} />
        </Routes>
      </BrowserRouter>
*/}
    </>
  </React.StrictMode>
);





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
