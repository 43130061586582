import './LoadingScreen.css';


/*
* Selbsterklärend. Einfacher loading screen :)
*/
function LoadingScreen(props) {
  return (
    <div id="loading-overlay">
        <div id="loading-screen">
        </div>
    </div>
  );
}

export default LoadingScreen;