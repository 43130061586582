import ProductSelection from "./components/product-selection";
import BackButton from "../../buttons/back-button-menu-hj.component";
import React from "react";
import {useMediaQuery} from "react-responsive";

function ProductSelectionView({productCategoryLabel, products, selectProduct}) {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;

  return (
    <>
      <ProductSelection productCategoryLabel={productCategoryLabel} products={products} selectProduct={selectProduct} />
      {
        isDesktopView && <div className='products-room-menu-container'>
            <span> <BackButton navigateTo="/products" transition="0" /> </span>
        </div>
      }
    </>
  );
}

export default ProductSelectionView;
