import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import '../../../App.css';
import '../../container/productinformationbox.css'
import {useWindowSize} from "../../../custom-hooks/useWindowSize";


function DesktopSustainabilityInformationBox({year, onScrolledToYear}) {
    const scrollContainerRef = useRef();

    const windowSize = useWindowSize();

    useEffect(() => {
        if (scrollContainerRef.current) {
            const anchor = document.getElementById(("timeline-" + year.year));
            try {
                let t2023 = document.getElementById(("timeline-text-" + "2023"));
                let t2025 = document.getElementById(("timeline-text-" + "2025"));
                let t2030 = document.getElementById(("timeline-text-" + "2030"));
                let t2039 = document.getElementById(("timeline-text-" + "2039"));

                t2023.classList.remove("timeline-active");
                t2025.classList.remove("timeline-active");
                t2030.classList.remove("timeline-active");
                t2039.classList.remove("timeline-active");
                if (year.year > 2021) {
                    let anchorText = document.getElementById(("timeline-text-" + year.year));
                    anchorText.classList.add("timeline-active");
                }
            } catch (e) { }
            scrollContainerRef.current.scroll({top: anchor.offsetTop, behavior: 'smooth'});
        }
    }, [year, windowSize]);

    const scrollContainerHeight = useMemo(() => {
        const anchor = document.getElementById(("timeline-" + year.year));
        if (anchor) {
            const anchorNextSibling = anchor.nextSibling;
            if (anchorNextSibling) {
                return anchor.clientHeight + anchorNextSibling.clientHeight;
            }
        }
        return undefined;
    }, [year, windowSize]);

    let bubbleOffset = "translate(11.35vh, 0px)";
    let textDivYOffset = "translateY(0px)";
    let timeLineHeight = "7vh";
    let timeLineLength = "12vh";
    let borderSize = "3px solid var(--main-handtmann-color)";
    let inactiveColor = "rgba(100,100,100, 0.55)";

    const onScroll = useCallback(() => {
        if (onScrolledToYear && scrollContainerRef.current) {
            const currentScrollTop = scrollContainerRef.current.scrollTop;
            let currentYear = 2021;
            for (let year of [2023, 2025, 2030, 2039]) {
                const element = document.getElementById(("timeline-" + year));
                // console.log(year, element, element.offsetTop);
                if (element && element.offsetTop > currentScrollTop) {
                    break;
                }
                currentYear = year;
            }
            onScrolledToYear(currentYear);
        }
    }, [onScrolledToYear, scrollContainerRef.current]);

    return (
        <React.Fragment>
            {/*<button onClick={handleToggleInformationBox} style={testxStyle}>toggle box tester</button>*/}
            <div id="technology-box-info" className="sx-infobox-wrapper">
                <div className="s-infobox-spacer" />
                {/*<div className="s-infobox-desc-text">At Handtmann, the growing importance of electric mobility for the future is also at heart of our development activities.</div>*/}
                <div className="s-infobox-desc-text"> </div>
                <div className="t-infobox-content-timeline" style={{ height: scrollContainerHeight != null ? `min(100vh, max(32%, ${scrollContainerHeight}px))` : undefined }} ref={scrollContainerRef} onScroll={onScroll}>
                    <table>
                        <tbody>
                            <tr id='timeline-2021'>
                                <td style={{ width: "40%", position: "relative", textAlign: "right" }} colSpan={1}>2021</td>
                                <td colSpan={1} style={{ width: "60%" }}>- START OF CLIMATE STRATEGY</td>
                            </tr>
                            <tr style={{ height: timeLineHeight }}>
                                <td colSpan={1} style={{ width: "40%" }}></td>
                                <td colSpan={1} style={{ width: "60%", borderLeft: borderSize }}></td>
                            </tr>
                            <tr id='timeline-2023' >
                                <td style={{ paddingBottom: "15px", paddingTop: "15px", position: "relative", textAlign: "right", width: "35px" }} colSpan={1}>2023</td>
                                <td colSpan={1} ><div style={{ borderBottom: borderSize, width: timeLineLength, transform: 'translateY(18px)' }}></div><span style={{ transform: bubbleOffset, display: "inline-grid", backgroundColor: "var(--main-handtmann-color)", borderRadius: "50%", width: "15px", height: "15px" }}></span>
                                </td>
                            </tr>
                            <tr style={{ height: { timeLineHeight } }}>
                                <td colSpan={1} style={{ width: "40%" }}></td>
                                <td colSpan={1} style={{ width: "60%", borderLeft: borderSize }}>
                                    <div style={{ overflow: "visible", textAlign: "left", fontSize: "10pt", color: inactiveColor, transform: { textDivYOffset }, paddingRight: "50px", paddingBottom: "15px", paddingTop: "15px", paddingLeft: "8px" }}>
                                        <span id='timeline-text-2023'>
                                            25% of proven green electricity in Biberach and Annaberg <br></br><br></br>
                                            Purchase of a defined amount of green electricity per sold 'green component'
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr id='timeline-2025' >
                                <td style={{ paddingBottom: "15px", paddingTop: "15px", position: "relative", textAlign: "right", width: "35px" }} colSpan={1}>2025</td>
                                <td colSpan={1} ><div style={{ borderBottom: borderSize, width: timeLineLength, transform: 'translateY(18px)' }}></div><span style={{ transform: bubbleOffset, display: "inline-grid", backgroundColor: "var(--main-handtmann-color)", borderRadius: "50%", width: "15px", height: "15px" }}></span>
                                </td>
                            </tr>
                            <tr style={{ height: { timeLineHeight } }}>
                                <td colSpan={1} style={{ width: "40%" }}></td>
                                <td colSpan={1} style={{ width: "60%", borderLeft: borderSize }}>
                                    <div style={{ overflow: "visible", textAlign: "left", fontSize: "10pt", color: inactiveColor, transform: { textDivYOffset }, paddingRight: "50px", paddingBottom: "15px", paddingTop: "15px", paddingLeft: "8px" }}>
                                        <span id='timeline-text-2025'>
                                            Increase in efficiency of machinery<br></br><br></br>
                                            Continuous investment in modern and
                                            energy-efficient machinery
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr id='timeline-2030'>
                                <td style={{ paddingBottom: "15px", paddingTop: "15px", position: "relative", textAlign: "right", width: "35px" }} colSpan={1}>2030</td>
                                <td colSpan={1} ><div style={{ borderBottom: borderSize, width: timeLineLength, transform: 'translateY(18px)' }}></div><span style={{ transform: bubbleOffset, display: "inline-grid", backgroundColor: "var(--main-handtmann-color)", borderRadius: "50%", width: "15px", height: "15px" }}></span>
                                </td>
                            </tr>
                            <tr style={{ height: { timeLineHeight } }}>
                                <td colSpan={1} style={{ width: "40%" }}></td>
                                <td colSpan={1} style={{ width: "60%", borderLeft: borderSize }}>
                                    <div style={{ overflow: "visible", textAlign: "left", fontSize: "10pt", color: inactiveColor, transform: { textDivYOffset }, paddingRight: "50px", paddingBottom: "15px", paddingTop: "15px", paddingLeft: "8px" }}>
                                        <span id='timeline-text-2030'>100% of green electricity in Biberach by using proof of origin
                                            or local increase in green electricity at other locations<br></br><br></br>
                                            Continuous investment in modern and
                                            energy- efficient machinery
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr id='timeline-2039'>
                                <td style={{ paddingBottom: "15px", paddingTop: "15px", position: "relative", textAlign: "right", width: "35px" }} colSpan={1}>2039</td>
                                <td colSpan={1} ><div style={{ borderBottom: borderSize, width: timeLineLength, transform: 'translateY(18px)' }}></div><span style={{ transform: bubbleOffset, display: "inline-grid", backgroundColor: "var(--main-handtmann-color)", borderRadius: "50%", width: "15px", height: "15px" }}></span>
                                </td>
                            </tr>
                            <tr style={{ height: { timeLineHeight } }}>
                                <td colSpan={1} style={{ width: "40%" }}></td>
                                <td colSpan={1} style={{ width: "60%", borderLeft: borderSize }}>
                                    <div style={{ overflow: "visible", textAlign: "left", fontSize: "10pt", color: inactiveColor, transform: { textDivYOffset }, paddingRight: "25px", paddingBottom: "15px", paddingTop: "15px", paddingLeft: "8px" }}>
                                        <div> <span id='timeline-text-2039'>Scope 2* CO<sub>2</sub> neutral<br></br> 100% green electricity at all locations<br>
                                        </br><br></br>
                                            Scope 1* CO<sub>2</sub>-neutral<br></br>
                                            Using biogas/green hydrogen, in applicable biogas with proof of origin
                                            <br>
                                            </br>
                                            <br></br>
                                            <span style={{ fontSize: "10px", fontWeight: "normal", overflow: 'visible' }}>
                                                *Bei den Scope 1-Emissionen handelt es sich um die direkten Emissionen, die am Standort durch die Verbrennung fossiler Energieträger wie insbesondere Erdgas oder Diesel entstehen.
                                                <br></br>
                                                Die sogenannten Scope 2-Emissionen sind indirekte Emissionen, die durch eingekaufte Energie wie Strom zugerechnet werden.</span>
                                        </span></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment >
    );
}

export default DesktopSustainabilityInformationBox;
