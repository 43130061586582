import { useEffect, useState } from "react";
import { default as axios } from "axios";
import { listProductsQueryConfig } from "../gql";

export function useProducts(filter) {
  const [products, setProducts] = useState(null);
  useEffect(() => {


    // GDPR variant:
    axios.get("https://gi021a7w4j.execute-api.eu-central-1.amazonaws.com/products")
      .then((response) => {
        let products = response.data;
        if (filter) {
          products = products.filter(filter);
        }
        setProducts(products);
      })
      .catch((error) => { console.log(error) })



    /*
  axios(listProductsQueryConfig)
    .then((response) => {
      let products = response.data.data.listProducts.data;
      if (filter) {
        products = products.filter(filter);
      }
      setProducts(products);
    }).catch(function (error) {
      console.log(error);
    });*/
  }, []);
  return products;
}
