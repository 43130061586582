import React, { useCallback, useEffect, useState } from "react";
import TransitionMovie from '../components/container/TransitionMovie';
import { useMediaQuery } from 'react-responsive';
import LocationsMobileView from "../components/locations/LocationsMobileView";
import LocationsDesktopView from "../components/locations/LocationsDesktopView";
import { useSearchParams } from "react-router-dom";


/*
* Hier ist auch einiges historisch gewachsen. die "ID" für die locations ist praktisch das "year"
* (Code wurde von Sustainability teilweise übernommen)
* Es gab mehrere Änderungen am Text, der Formatierung etc.
* Ich kann es in ruhe mal aufräumen, aber habe das auch schon länger nicht mehr angerührt,
* und will jetzt ungern auf die schnelle was (kaputt) machen
*/
const locations_all = [
  {
    year: 2021,
    locationName: "BIBERACH",
    desc: "Headquarters - Technology and innovation center",
    addressFull: "Albert Handtmann Metallgusswerk GmbH & Co. KG\n88400 Biberach\nArtur-Handtmann-Straße 25-31\nGermany",
    secAddress: ["<b>Assembly of complex systems<b>\nHandtmann Systemtechnik GmbH & Co. KG\nArthur-Handtmann-Straße 7/1\n88400 Biberach\nGermany", "<b>Phone:</b> +49 7351 342-0\n<b>Fax:</b> +49 7351 342-8470\n<b>E-mail:</b> info.systemtechnik@handtmann.de"],
    textfull: "<b>Phone:</b> +49 7351 342-0\n<b>Fax:</b> +49 7351 342-6470\n<b>E-mail:</b> info.foundry@handtmann.de",
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh29-2021.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2021.png'),
    free_text: "Everybody talks about sustainability, climate protection and its importance, but what are the steps to achieve these goals?\nWith our official climate strategy, we would like to give our previous actions and measures a framework and structure. At the same time, we support our customers on their way to sustainable mobility as we pursue a common goal.\nWe have defined the following core elements: Reduction our products’ CO2 emissions over their life cycle, expansion of renewable energies, continuous increase of the share of green electricity in our electricity mix and continuous improvement of energy efficiency.\nThe year 2021 marks the beginning of our climate strategy.",
    bulletpoints: [
      "Start of Climate strategy",
    ],
  },
  {
    locationName: "North America",
    desc: "coming soon...",
    addressFull: "coming soon...",
    secAddress: [],
    textfull: "",
    company: "",
    address: "",
    phone: "",
    fax: "",
    email: "",
    year: 2023,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh2s-2023.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2023.png'),
    free_text: "Every start is small, but also crucial.\nBy 2023, we aim to purchase a defined amount of green power for 'green' components sold.\nIn addition, we will be able to demonstrate 25% green power. Initially, we will concentrate on our German sites in Biberach and Annaberg.",
    bulletpoints: [
      "25% of proven green electricity in Biberach and Annaberg",
      "Purchase of a defined amount of green electricity per sold 'green component'",
    ],
  },
  {
    locationName: "ANNABERG",
    desc: "Die casting - machining - assembly of systems",
    addressFull: "Handtmann Leichtmetallgießerei Annaberg GmbH\nSehmatalstraße 16\n09456 Annaberg-Buchholz\nGermany",
    secAddress: ["Handtmann Leichtmetallgießerei Annaberg GmbH\n- Königswalde facility\nGewerbegebiet 4\n09471 Königswalde\nGermany", "<b>E-mail:</b> info.foundry@handtmann.de"],
    textfull: "<b>Phone:</b> +49 3733 89-0\n<b>Fax:</b> +49 3733 89-201\n<b>E-mail:</b> info.foundry@handtmann.de",
    company: "Handtmann Metallgusswerke",
    address: "adrress1 and2 and 3",
    phone: "phone",
    fax: "fax",
    email: "email",
    year: 2024,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh2s-2023.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2023.png'),
    free_text: "Every start is small, but also crucial.\nBy 2023, we aim to purchase a defined amount of green power for 'green' components sold.\nIn addition, we will be able to demonstrate 25% green power. Initially, we will concentrate on our German sites in Biberach and Annaberg.",
    bulletpoints: [
      "25% of proven green electricity in Biberach and Annaberg",
      "Purchase of a defined amount of green electricity per sold 'green component'",
    ],
  },
  {
    locationName: "KOŠICE / KECHNEC",
    desc: "Die casting - machining - assembly of systems",
    company: "Handtmann Metallgusswerke",
    textfull: "<b>Phone:</b> +421 55 649 00 06\n<b>E-mail:</b> info-kechnec@handtmann.sk",
    addressFull: "Handtmann Kechnec s.r.o.\nTrat'ová 9\n044 58 Kechnec\nSlovakia",
    secAddress: ["Handtmann Slovakia, s.r.o.\nPerinska cesta 340\n040 18 Košice\nSlovakia", "<b>Phone:</b> +421 55 789 05 11\n<b>Fax:</b> +421 55 789 05 70\n<b>E-mail:</b> info@handtmann.sk"],
    address: {
      street: "straße",
      zip: "zip",
      city: "city",
    },
    phone: "phone",
    fax: "fax",
    email: "email",
    year: 2025,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh3i-2025.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2025.png'),
    free_text: "As with any strategy, changes are indispensable for further progress and the achievement of goals.\nWe will continue to invest in modern and energy-efficient machinery until 2025. In this way, we will gradually fulfil our climate strategy and our customers will benefit from optimised production processes. Furthermore, we are increasing the share of green electricity at our German site in Biberach.",
    bulletpoints: [
      "Increase of efficiency of machinery",
      "Continous investment in modern and energy-efficient machinery",
    ]
  },
  {
    locationName: "TIANJIN",
    desc: "Die casting - machining - assembly of systems",
    addressFull: "Handtmann Light Metal Foundry (Tianjin) Co., Ltd.\nTianjin Airport Economic Area\n300308 Tianjin\nChina",
    secAddress: [],
    textfull: "<b>Phone:</b> +86 22 2492 8222",
    address: {
      street: "straße",
      zip: "zip",
      city: "city",
    },
    phone: "phone",
    fax: "fax",
    email: "email",
    year: 2030,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh42-2030.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2030.png'),
    free_text: "The year 2030 is also a significant milestone for our climate strategy.\nWe will continue to invest in a modern and energy-efficient plant fleet until the beginning of the next decade.\nWe have divided the next target into two possible scenarios. The first outcome is that we demonstrate 100% green power through guarantees of origin at our German site in Biberach. The second outcome is shown by locally increasing green power at our other sites in Germany, China and Slovakia.",
    bulletpoints: [
      "100% of green electricity in Biberach by using proof of origin or local increase in green electricity at other locations",
      "Continuous investment in modern and energy-efficient machinery",
    ]
  },
];


function getLocationById(locationId) {
  let resultYear;
  locations_all.forEach(year => {
    if (year.year === locationId) {
      resultYear = year;
    }
  });
  return resultYear;
}

const Locations = ({ changeLayeredMovies, changeBackgroundImage }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;

  const [searchParams] = useSearchParams();
  const transitionQueryParam = searchParams.get('tx');

  const [transitionMoviePlaying, setTransitionMoviePlaying] = useState(transitionQueryParam !== "0");
  const [currentLocation, setCurrentLocation] = useState(locations_all[0]);


  const changeLocation = useCallback((param) => {
    const yearData = getLocationById(param);
    setCurrentLocation(yearData);
  }, [setCurrentLocation]);

  const resetLocation = useCallback(() => {
    setCurrentLocation(null);
  }, [setCurrentLocation]);

  const showContent = useCallback(() => {
    setTransitionMoviePlaying(false);
  }, [setTransitionMoviePlaying]);


  useEffect(() => {
    const lazyMovie = async () => {
      if (!transitionMoviePlaying) {
        changeBackgroundImage("locations/Standorte_final.jpg");
        changeLayeredMovies([]);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 700));
        changeBackgroundImage("locations/Standorte_final.jpg");
        changeLayeredMovies([]);
      }
    };
    lazyMovie();
    //window._mtm.push({ 'event': 'mtm.PageView' });
  }, []);


  if (transitionMoviePlaying) {
    return (
      <main>
        <TransitionMovie video="Anflug_Standorte_final.mp4" duration={3980} onFinished={showContent} />
      </main>
    );
  }
  if (isDesktopView) {
    return (
      <LocationsDesktopView currentLocation={currentLocation} changeLocation={changeLocation} locations={locations_all} />
    );
  }
  return (
    <LocationsMobileView currentLocation={currentLocation} resetLocation={resetLocation} changeLocation={changeLocation} locations={locations_all} />
  );
}

export default Locations;
