import '../../App.css';
import './back-button.css';
import {useNavigate} from 'react-router-dom';
import {BsArrowLeft} from 'react-icons/bs';
import {useCallback, useMemo} from "react";


function BackButton({ navigateTo, transition }) {
    const navigate = useNavigate();
    const finalNavigateTo = useMemo(() => {
        return (navigateTo + "?tx=" + transition)
    }, [navigateTo, transition]);
    const navigateCallback = useCallback(() => navigate(finalNavigateTo), [navigate, finalNavigateTo]);

    return (
        <div id="backButton" onClick={navigateCallback} className="menu-back-button">
            <div className='back-button-container'>
                <div className='back-button-wrapper'>
                    <div className='back-button-content inactive-color'>
                        <BsArrowLeft /> back
                    </div>
                </div>
            </div>
            {/*             <div style={buttonDivStyle}>
                <BsArrowLeft style={{border: '0px solid white', backgroundColor: 'white'}} fontWeight={'bold'} fontSize={'36px'} color='black'></BsArrowLeft> back
            </div> */}
        </div>
    );
}

export default BackButton;
