import React from "react";
import "./product-selection.css";
import ProductSelectionProductsList from "./product-selection-products-list";
import {useMediaQuery} from "react-responsive";
import classNames from "classnames";

function ProductSelection ({ productCategoryLabel, products, selectProduct }) {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;

  const containerClassName = classNames("product-selection", { "product-selection--desktop": isDesktopView, "hide-scrollbar": isDesktopView });

  return (
    <div className={containerClassName}>
      <div className="category-label">
        <label>{productCategoryLabel}</label>
      </div>
      <ProductSelectionProductsList useGrid={isDesktopView} products={products} selectProduct={selectProduct} />
    </div>
  );
}

export default ProductSelection;
