import React, {useCallback, useEffect, useState} from "react";
import '../components/buttons/hj-callouts.css';
import TransitionMovie from '../components/container/TransitionMovie';
import {useMediaQuery} from 'react-responsive';
import {useSearchParams} from "react-router-dom";
import SustainabilityMobileView from "../components/sustainability/SustainabilityMobileView";
import SustainabilityDesktopView from "../components/sustainability/SustainabilityDesktopView";

const sustainability_years = [
  {
    year: 2021,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh29-2021.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2021.png'),
    free_text: "Everybody talks about sustainability, climate protection and its importance, but what are the steps to achieve these goals?\nWith our official climate strategy, we would like to give our previous actions and measures a framework and structure. At the same time, we support our customers on their way to sustainable mobility as we pursue a common goal.\nWe have defined the following core elements: Reduction our products’ CO2 emissions over their life cycle, expansion of renewable energies, continuous increase of the share of green electricity in our electricity mix and continuous improvement of energy efficiency.\nThe year 2021 marks the beginning of our climate strategy.",
    bulletpoints: [
      "Start of Climate strategy",
    ],
  },
  {
    year: 2023,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh2s-2023.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2023.png'),
    free_text: "Every start is small, but also crucial.\nBy 2023, we aim to purchase a defined amount of green power for 'green' components sold.\nIn addition, we will be able to demonstrate 25% green power. Initially, we will concentrate on our German sites in Biberach and Annaberg.",
    bulletpoints: [
      "25% of proven green electricity in Biberach and Annaberg",
      "Purchase of a defined amount of green electricity per sold 'green component'",
    ],
  },
  {
    year: 2025,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh3i-2025.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2025.png'),
    free_text: "As with any strategy, changes are indispensable for further progress and the achievement of goals.\nWe will continue to invest in modern and energy-efficient machinery until 2025. In this way, we will gradually fulfil our climate strategy and our customers will benefit from optimised production processes. Furthermore, we are increasing the share of green electricity at our German site in Biberach.",
    bulletpoints: [
      "Increase of efficiency of machinery",
      "Continous investment in modern and energy-efficient machinery",
    ],
  },
  {
    year: 2030,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh42-2030.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2030.png'),
    free_text: "The year 2030 is also a significant milestone for our climate strategy.\nWe will continue to invest in a modern and energy-efficient plant fleet until the beginning of the next decade.\nWe have divided the next target into two possible scenarios. The first outcome is that we demonstrate 100% green power through guarantees of origin at our German site in Biberach. The second outcome is shown by locally increasing green power at our other sites in Germany, China and Slovakia.",
    bulletpoints: [
      "100% of green electricity in Biberach by using proof of origin or local increase in green electricity at other locations",
      "Continuous investment in modern and energy-efficient machinery",
    ],
  },
  {
    year: 2039,
    image: "https://dh1qnw2i6ba2u.cloudfront.net/files/9l8iqoh50-2039.png",
    carouselImage: require('../assets/webm/stills/sustainability/Sprout_2039.png'),
    free_text: "The major goal of our climate strategy is to achieve CO2 neutrality for all production sites of the Light Metal Castings and Systems Engineering business units by 2039.\nThe first stage is 'Scope2 neutrality'. Here, all sites in Germany, China and Slovakia will achieve 100% green electricity. For further explanation: The so-called Scope2 emissions are indirect emissions that are attributed through purchased energy, such as electricity.\nThe second stage is 'Scope1 neutrality', using biogas or green hydrogen. Scope1 emissions are the direct emissions generated at the site through the combustion of fossil fuels, such as natural gas or diesel in particular.",
    bulletpoints: [
      "Scope 2x CO2-neutral - 100% of green electricy at all locations",
      "Scope 1x CO2-neutral - Using biogas/green hydrogen, if applicable biogas with proof of origin",
    ],
  },
];

const Sustainability = ({ changeBackgroundImage, changeLayeredMovies }) => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isDesktopView = isDesktopOrLaptop && !isPortrait;

  const [searchParams] = useSearchParams();
  const transitionQueryParam = searchParams.get('tx');

  const [transitionMoviePlaying, setTransitionMoviePlaying] = useState(transitionQueryParam !== "0");

  const [currentYear, setCurrentYear] = useState(sustainability_years[0]);

  const showContent = useCallback(() => {
    setTransitionMoviePlaying(false);
  }, [setTransitionMoviePlaying]);


  useEffect(() => {
    const lazyMovie = async () => {
      if (!transitionMoviePlaying) {
        changeBackgroundImage("sustainability/Technology_Sustainabilty_10a_V3_Scene_Finish_01c_bg.jpg");
        changeLayeredMovies([]);
      } else {
        // Wait for some time
        await new Promise((resolve) => setTimeout(resolve, 700));
        // Toggle loading state
        changeBackgroundImage("sustainability/Technology_Sustainabilty_10a_V3_Scene_Finish_01c_bg.jpg");
        changeLayeredMovies([]);
      }
    };
    lazyMovie();
  }, []);


  if (transitionMoviePlaying) {
    return (
      <main>
        <TransitionMovie video="Anflug_Nachhaltigkeit_[00000-00120].webm" duration={4000} onFinished={showContent} />
      </main>
    );
  }
  if (isDesktopView) {
    return (
      <SustainabilityDesktopView sustainabilityYears={sustainability_years} currentYear={currentYear} setCurrentYear={setCurrentYear} />
    );
  }
  return (
    <SustainabilityMobileView sustainabilityYears={sustainability_years} currentYear={currentYear} setCurrentYear={setCurrentYear} />
  );
}

export default Sustainability;
