import React, {useCallback} from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import LoadingScreen from "../components/container/LoadingScreen";
import {useTechnologies} from "../custom-hooks/useTechnologies";
import {useTechnologyBy} from "../custom-hooks/useTechnologyBy";
import SystemsEngineeringRoomView from "../components/technology/systems-engineering/SystemsEngineeringRoomView";

function TechnologySystemsEngineeringBatteryTestingRoom({changeBackgroundImage, changeBackgroundImageStyleOverride, changeLayeredMovies}) {
  const technologies = useTechnologies();
  const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "BAT");

  const setupBackground = useCallback(() => {
    changeBackgroundImage("empty_page.png");
    changeLayeredMovies(["Competence_Batterysystems_01a.mp4"]);
  },[changeBackgroundImage, changeLayeredMovies]);

  return (
    <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_competence_Batterysystems.webm" duration={2150} >
      { !technology && <LoadingScreen /> }
      { technology && <SystemsEngineeringRoomView technology={technology} changeBackgroundImageStyleOverride={changeBackgroundImageStyleOverride} />}
    </TransitionMovieContentWrapper>
  );
}

export default TechnologySystemsEngineeringBatteryTestingRoom;
