import React, {useCallback} from "react";
import styled from "styled-components";

const Header = styled.div`
  background-color: var(--heading-background-color);
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 3%;
  padding: 0 12px;
`;

const ActiveButton = styled.button`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  border: none;
  outline: none;
  background-color: var(--main-handtmann-color);
  color: white;
  min-height: 34px;
  text-align: center;
  font-size: 18.24px;
  font-weight: bold;
  line-height: 34px;
  user-select: none;
  
  &:not(:last-child) {
    margin-bottom: 3%;
  }
`;

const InactiveButton = styled(ActiveButton)`
  opacity: 0.95;
  background-color: var(--main-handtmann-color-inactive)
`

function ProductButton({product, currentProduct, selectProduct}) {
  const selectCallback = useCallback(() => {
    selectProduct(product.productId);
  }, [product, selectProduct])

  if (product.productId === currentProduct.productId) {
    return (
      <ActiveButton onClick={selectCallback}>{product.productName.toUpperCase()}</ActiveButton>
    );
  }
  return (
    <InactiveButton onClick={selectCallback}>{product.productName.toUpperCase()}</InactiveButton>
  );
}

function ProductSelection({products, currentProduct, category, selectProduct}) {
  return (
    <div>
      <Header>{category}</Header>
      {products.map(product => <ProductButton key={product.productId} product={product} currentProduct={currentProduct} selectProduct={selectProduct} />)}
    </div>
  );
}

export default ProductSelection;
