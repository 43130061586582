import React, {useCallback} from "react";
import "./product-selection.css";
import classNames from "classnames";

function ProductsListItem({product, selectProduct}) {
  const selectProductCallback = useCallback(() => {
    selectProduct(product.productId)
  }, [selectProduct, product]);

  return (
    <div className="products-list-item" onClick={selectProductCallback} role="button">
      <div className="product-image-wrapper">
        <img className="product-image" alt="" src={product.productFiles.overviewImage} />
        <div className="product-footer">
          <label className="product-label">{product.productName.toUpperCase()}</label>
        </div>
      </div>
    </div>
  );
}

function ProductSelectionProductsList({useGrid, products, selectProduct }) {
  const containerClassName = classNames("products-list", {"products-list--grid": useGrid})
  const containerStyle = Object.assign({}, useGrid && { justifyContent: products.length <= 2 ? "center" : "space-evenly" });
  return (
    <div className={containerClassName} style={containerStyle}>
      {
        products.map((product) => {
          return <ProductsListItem key={product.productId} product={product} selectProduct={selectProduct} />
        })
      }
    </div>
  );
}

export default ProductSelectionProductsList;
