import React from 'react';
import TechnologyInformationDetails from "./TechnologyInformationDetails";
import styled from "styled-components";


const Container = styled.div`
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 36%;
    max-width: 600px;
    z-index: 50;
    display: flex;
    flex-direction: column;
    padding: 80px 0 20px 0;
`;

const ScrollContainer = styled.div`
    flex: 1 1 auto;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 24px;
`;

function TechnologyInformationBox({ technology, openVideoOverlay }) {
    return (
        <Container>
            <ScrollContainer>
                <TechnologyInformationDetails technology={technology} openVideoOverlay={openVideoOverlay} />
            </ScrollContainer>
        </Container>
    );
}

export default TechnologyInformationBox;
