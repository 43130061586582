import '../../App.css';
import { Link } from 'react-router-dom';
import './responsive-callouts.css';
import {useMemo} from 'react';
import {useWindowSize} from "../../custom-hooks/useWindowSize";

function GetVideoScaling() {
    const bgVideos = document.getElementsByClassName('background-video-redesign');
    if (bgVideos.length > 0) {
        let bgVideoScaling = {};
        const videoBackground = bgVideos[0];
        bgVideoScaling.x = videoBackground.clientWidth;
        bgVideoScaling.y = videoBackground.clientHeight;
        return bgVideoScaling;
    } else {
        const bgImage = document.getElementsByClassName('background-layer-still')[0];
        let bgImageScaling = {};
        bgImageScaling.x = bgImage.clientWidth;
        bgImageScaling.y = bgImage.clientHeight;
        return bgImageScaling;
    }
}

function computeButtonPositions(windowWidth, windowHeight) {
    const absoluteBackgroundSize = GetVideoScaling();

    // calculate position:
    let theSizeXRight = windowWidth; // for buttons with relative value > 0.5!
    let theSizeXLeft = windowWidth;
    let theSizeY = absoluteBackgroundSize.y;


    if (absoluteBackgroundSize.x > windowWidth) {
        let diff = absoluteBackgroundSize.x - windowWidth;
        let h_diff = diff / 4;
        theSizeXRight += h_diff;
        theSizeXLeft -= h_diff;
    }

    const SIDES_MARGIN_VALUE = 35;
    const BUTTON_WIDTH_VALUE = 165;

    // Get the base Values(not with collision in mind yet)
    let pValue = (theSizeXRight * 0.725);
    let cValue = (theSizeXLeft * 0.385);
    let sValue = (theSizeXLeft * 0.285);
    let lValue = (theSizeXLeft * 0.385);
    let aValue = (theSizeXRight * 0.530);

    // colliding from the right: Products and AboutUs
    let colValue_p = pValue + BUTTON_WIDTH_VALUE;
    if (colValue_p >= windowWidth) {
        pValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }
    let colValue_a = aValue + BUTTON_WIDTH_VALUE;
    if (colValue_a >= windowWidth) {
        aValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }
    // colliding from the left: Sustainability, competences and Locations but fix ALL at left "SIDES_MARGIN_VALUE" anyway
    pValue = pValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : pValue;
    aValue = aValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : aValue;
    sValue = sValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : sValue;
    cValue = cValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : cValue;
    lValue = lValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : lValue;

    return {
        pButton: {
            left: pValue + "px",
            top: Math.max(Math.min((theSizeY * 0.135), windowHeight - 75), 75) + "px",
        },
        cButton: {
            left: cValue + "px",
            top: Math.max(Math.min((theSizeY * 0.245), windowHeight - 75), 75) + "px",
        },
        sButton: {
            left: sValue + "px",
            top: Math.max(Math.min((theSizeY * 0.435), windowHeight - 75), 75) + "px",
        },
        lButton: {
            left: lValue + "px",
            top: Math.max(Math.min((theSizeY * 0.635), windowHeight - 75), 75) + "px",
        },
        aButton: {
            left: aValue + "px",
            top: Math.max(Math.min((theSizeY * 0.765), windowHeight - 75), 75) + "px",
        },
    };
}


function ProductsCalloutButton() {
    const windowSize = useWindowSize();
    const buttonPositions = useMemo(() => {
        return computeButtonPositions(windowSize.width, windowSize.height);
    }, [windowSize.width, windowSize.height]);

    return (
      <div className='fade-in-button redesign-landingpage-buttons-container'>
          <Link to={"/products"}>
              <div id='redesign-products-button' className='redesign-landingpage-products-button-wrapper' style={buttonPositions.pButton}>
                  <div className='redesign-landingpage-products-button' >
                      <div className='redesign-button' >
                          PRODUCTS
                      </div>
                  </div>
              </div>
          </Link>
          <Link to={"/competences"}>
              <div id='redesign-competences-button' className='redesign-landingpage-products-button-wrapper' style={buttonPositions.cButton}>
                  <div className='redesign-landingpage-products-button' >
                      <div className='redesign-button' >
                          COMPETENCES
                      </div>
                  </div>
              </div>
          </Link>
          <Link to={"/sustainability"}>
              <div id='redesign-sustainability-button' className='redesign-landingpage-products-button-wrapper' style={buttonPositions.sButton}>
                  <div className='redesign-landingpage-products-button' >
                      <div className='redesign-button' >
                          SUSTAINABILITY
                      </div>
                  </div>
              </div>
          </Link>
          <Link to={"/locations"}>
              <div id='redesign-locations-button' className='redesign-landingpage-products-button-wrapper' style={buttonPositions.lButton}>
                  <div className='redesign-landingpage-products-button' >
                      <div className='redesign-button' >
                          LOCATIONS
                      </div>
                  </div>
              </div>
          </Link>
          <a href='https://www.handtmann.de/en/light-metal-casting' target={"_blank"}>
              <div id='redesign-aboutus-button' className='redesign-landingpage-products-button-wrapper' style={buttonPositions.aButton}>
                  <div className='redesign-landingpage-products-button' >
                      <div className='redesign-button' >
                          ABOUT US
                      </div>
                  </div>
              </div>
          </a>
      </div>
    );
}

export default ProductsCalloutButton;
