import React, { useCallback, useContext, useEffect, useState } from "react";
import TransitionMovie from '../components/container/TransitionMovie';
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingScreen from '../components/container/LoadingScreen';
import './products-page.css';
import '../components/buttons/hj-callouts.css';
import { useProductsFilteredByCategory } from "../custom-hooks/useProductsFilteredByCategory";
import { useProductById } from "../custom-hooks/useProductById";
import AppStateContext from "../contexts/appStateContext";
import ProductDetailsView from "../components/products/product-details/product-details-view";
import ProductSelectionView from "../components/products/product-selection/product-selection-view";


const ProductCategoryRoute = ({ productCategory, productCategoryLabel, route, changeLayeredMovies, changeBackgroundImage }) => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const { setMobileBackButtonLocation, setMobileBackButtonVisible } = useContext(AppStateContext);

    const transitionQueryParam = searchParams.get('tx');
    const productIdQueryParam = searchParams.get('productId');

    const [currentProductId, setCurrentProductId] = useState(productIdQueryParam);
    const products = useProductsFilteredByCategory(productCategory);
    const currentProduct = useProductById(products, currentProductId);

    const [transitionMoviePlaying, setTransitionMoviePlaying] = useState(transitionQueryParam !== "0" && !currentProductId);
    const onTransitionMovieFinished = useCallback(() => setTransitionMoviePlaying(false), [setTransitionMoviePlaying]);

    const selectProduct = useCallback((productId) => {
        setCurrentProductId(productId)
        setSearchParams("productId=" + productId);
    }, [setCurrentProductId, setSearchParams]);

    const resetProduct = useCallback(() => {
        setCurrentProductId(null);
        setSearchParams("");
    }, [setCurrentProductId, setSearchParams]);

    useEffect(() => {
        // Sync product id query param to selected product.
        productIdQueryParam ? selectProduct(productIdQueryParam) : resetProduct();
    }, [productIdQueryParam, selectProduct, resetProduct]);

    useEffect(() => {
        // Update mobile back button.
        setMobileBackButtonVisible(true);
        setMobileBackButtonLocation(currentProductId ? route : "/products");
    }, [currentProductId, setMobileBackButtonLocation]);

    useEffect(() => {
        const lazyMovie = async () => {
            if (!transitionMoviePlaying) {
                changeBackgroundImage("products/Products_Motor_u_Antriebskomponenten_01f_Scene.jpg");
                changeLayeredMovies([]);
            } else {
                // Wait for some time
                await new Promise((resolve) => setTimeout(resolve, 700));
                // Toggle loading state
                changeBackgroundImage("products/Products_Motor_u_Antriebskomponenten_01f_Scene.jpg");
                changeLayeredMovies([]);
            }
        };
        lazyMovie();
        //window._mtm.push({ 'event': 'mtm.PageView' });
    }, [location.pathname]);


    if (transitionMoviePlaying) {
        return (
            <TransitionMovie video="Anflug_Produktion_Motor_u_Antriebskomponenten_[00000-00068].webm"
                duration={2120}
                onFinished={onTransitionMovieFinished} />
        );
    }
    if (currentProduct) {
        return (
            <main>
                <ProductDetailsView products={products}
                    currentProduct={currentProduct}
                    category={productCategoryLabel}
                    selectProduct={selectProduct}
                    resetProduct={resetProduct} />
            </main>
        );
    }
    if (!currentProductId && products) {
        return (
            <main>
                <ProductSelectionView productCategoryLabel={productCategoryLabel} products={products} selectProduct={selectProduct} />
            </main>
        );
    }
    return (
        <LoadingScreen />
    );
}

export default ProductCategoryRoute;
