import { React } from 'react';
import './BackdropModalVideo.css';


function OnClickedOutsideCloseVideo(evt, closeCallback) {
    if (evt.target.id == "backdrop-modal-video") {
        closeCallback();
    }
}

function BackdropModalVideoSimple(props) {
    return (
        <>
            <div id='backdrop-modal-video' className='backdrop-modal-video-container' onClick={(event) => OnClickedOutsideCloseVideo(event, props.handleCloseVideoOverlay)}>
                <div className='backdrop-modal-video-content-wrapper-simple'>
                    <div className='backdrop-modal-video-content-simple'>
                        <div className='backdrop-modal-video-video-simple'>
                            <video id='video-overlay-video' src={props.source} controls>Oops! It seems that your browser does not support videos.</video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
// ******* Verwendetes Modal *******
function BackdropModalVideo(props) {
    return (
        <>
            <div id='backdrop-modal-video' style={{ zIndex: '9997', position: 'absolute', width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.7)' }} onClick={(event) => OnClickedOutsideCloseVideo(event, props.handleCloseVideoOverlay)}>
                <div style={{ width: '70%', height: 'auto', position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <video style={{ width: '100%', height: 'auto' }} id='video-overlay-video' src={props.source} controls>Oops! It seems that your browser does not support videos.</video>
                </div>
            </div>
        </>
    )
}

function BackdropModalVideox(props) {
    return (
        <>
            <div id='backdrop-modal-video' className='backdrop-modal-video-container' onClick={(event) => OnClickedOutsideCloseVideo(event, props.handleCloseVideoOverlay)}>
                <div className='backdrop-modal-video-content-wrapper'>
                    <div className='backdrop-modal-video-content'>
                        <div className='backdrop-modal-video-header-banner'>
                            <span className='backdrop-modal-video-close-button' onClick={() => props.handleCloseVideoOverlay()}>CLOSE</span>
                        </div>
                        <div className='backdrop-modal-video-heading'>
                            <h1>{props.title}</h1>
                        </div>
                        <div className='backdrop-modal-video-video'>
                            <video id='video-overlay-video' src={props.source} autoPlay controls>Oops! It seems that your browser does not support videos.</video>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default BackdropModalVideo;