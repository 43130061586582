import React, { useEffect } from 'react';

const MatomoTracker = ({ siteId, matomoUrl }) => {

  useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    console.log("starting mtm session");
    (function () {
      var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.async = true; g.type="text/plain"; g.setAttribute("data-usercentrics", "Matomo Tag Manager"); g.src = 'https://handtmann-analytics.creapolis.de/js/container_dg7VRXNX.js'; s.parentNode.insertBefore(g, s);
    })();
  }, [siteId, matomoUrl]);

  return null;
};

export default MatomoTracker;