import React from "react";
import styled from "styled-components";

import SustainabilityCarouselImage from "./components/SustainabilityCarouselImage";
import DesktopSustainabilityInformationBox from "./components/DesktopSustainabilityInformationBox";
import BackButton from "../buttons/back-button-menu-hj.component";
import SustainabilityYearSelection from "./components/SustainabilityYearSelection";


const SelectionContainer = styled.div`
  position: absolute;
  bottom: 5%;
  left: 3%;
  width: 325px;
`;

function SustainabilityDesktopView({ sustainabilityYears, currentYear, setCurrentYear }) {
  return (
    <main>
      <SustainabilityCarouselImage productCarouselImage={currentYear.carouselImage} />
      <DesktopSustainabilityInformationBox year={currentYear} />
      <SelectionContainer>
        <SustainabilityYearSelection sustainabilityYears={sustainabilityYears} currentYear={currentYear} setCurrentYear={setCurrentYear} />
        <BackButton transition="0" navigateTo="/" />
      </SelectionContainer>
    </main>
  );
}

export default SustainabilityDesktopView;
