import '../../App.css';
import './ProductsCarouselImage.css';
import {useWindowSize} from "../../custom-hooks/useWindowSize";
import {useMemo} from "react";

/**
 *
 * @param windowWidth
 * @param windowHeight
 * @return {{width: string, height: string}}
 */
function computeImageSize(windowWidth, windowHeight) {
  const aspectRatio = 1920 / 1080;
  const windowAspectRatio = windowWidth / windowHeight;
  if (windowAspectRatio > aspectRatio) {
    return {
      width: '100vw',
      height: 'auto',
    };
  }
  return {
    width: 'auto',
    height: '100vh',
  };
}

/**
 * Das Bild des Produkts, welches auf Podest im Produkte Raum schwebt.
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function ProductsCarouselImage(props) {
  const windowSize = useWindowSize();
  const imageSize = useMemo(
    () => computeImageSize(windowSize.width, windowSize.height),
    [windowSize.width, windowSize.height]
  );

  return (
    <div className="globalContainer" id="global_container-carousel">
      <div id="endvr_scroll_div-carousel" >
        <div id="endvr_scroll_contaner_div-carousel">
          <div className="bg-image-div fade-in-image" id="endvr_bg_image-carousel">
            <div className='carousel-images-wrapper'>
              <img style={{ width: imageSize.width, height: imageSize.height }}
                   className='carousel-shadow-img floating-shadow'
                   width={1920} height={1080}
                   src={props.productCarouselPodest} />
              <img style={{ width: imageSize.width, height: imageSize.height  }}
                   className='carousel-product-img floating-element'
                   width={1920} height={1080}
                   src={props.productCarouselImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsCarouselImage;
