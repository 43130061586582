import React from "react";
import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

const axios = require('axios').default;

/*
*   Sagt ja genaugenommen der Name schon, ist ein Test gewesen für's caching von Inhalten.
*   Haben wir aber nie groß verfolgt.
*/
const CacheTest = ({ changeLayeredMovies, changeBackgroundImage }) => {

    // Set loading state to true initially
    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const transition = searchParams.get('tx');
    const [picCache, setPicCache] = useState();

    const onClickHandler = () => {
        console.log("Clicked on button!");
    }

    /*
    async function createIDBStoreAsync() {
        const db = await openDB('asyncdb1', 1, {
            upgrade(db) {
                db.createObjectStore('asyncstore1');
            }
        })
    };
*/
    async function fetchFile(url) {
        url = "http://10.0.0.168:3000/static/media/Loop_Produktion_NoAlpha[00000-00249].9ee625c7abfae6f8a4ba.webm";
        axios.get(url)
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    };



    useEffect(() => {
        const lazyMovie = async () => {
            // load data
            //const newCache = await caches.open("pics");
            //setPicCache(newCache);
        };
        lazyMovie();
    }, [])

    /*
    function createIDBStore() {

        openDB('db1', 1, {
            upgrade(db) {
                db.createObjectStore('store1');
            }
        })
    }
*/

    return (
        <></>
        /*
        <main style={{ padding: "1rem 0" }}>
            <p></p><h1><br></br><br></br>
                Test Cache:
            </h1>
            <button onClick={fetchFile}>Fetch file</button><br></br>
            <button onClick={createIDBStore}>Create IDB Store</button><br></br>
            <button onClick={createIDBStoreAsync}>async Create IDB Store</button><br></br>
        </main>
        */
    )


}

export default CacheTest;