import styled from "styled-components";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const Title = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  min-height: 34px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  
  background-color: var(--heading-background-color);
  
  &:not(:last-child) {
    margin-bottom: 3%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  border: none;
  outline: none;
  background-color: var(${props => props.$highlighted ? "--main-handtmann-color" : "--main-handtmann-color-inactive"});
  opacity: ${props => props.$highlighted ? 1 : 0.95};
  color: white;
  min-height: 34px;
  text-align: center;
  font-size: 18.24px;
  font-weight: bold;
  line-height: 34px;
  user-select: none;
  
  &:hover {
    color: white;
    background-color: var(--main-handtmann-color);
  }

  &:not(:last-child) {
    margin-bottom: 3%;
  }
`;

const ROUTES = [
  { title: "RAPID PROTOTYPING", route: "/competences/rapid-prototyping?tx=0" },
  { title: "OPTIMISATION", route: "/competences/optimisation?tx=0" },
  { title: "SIMULATIONS", route: "/competences/simulations?tx=0" },
];

function EngDevelopmentTechnologySelection() {
  const location = useLocation();

  return (
    <>
      <Title>ENGINEERING AND DEVELOPMENT</Title>
      {ROUTES.map(route =>
        <StyledLink key={route.route} to={route.route} $highlighted={route.route.startsWith(location.pathname)}>
          {route.title}
        </StyledLink>
      )}
    </>
  );
}

export default EngDevelopmentTechnologySelection;
