import React, { useCallback } from "react";
import styled from "styled-components";

const StyledListItem = styled.li`
  text-align: left;
  padding-left: 0.5rem;
  list-style: none;
  cursor: pointer;
  line-height: 2.3rem;
  user-select: none;
  color: black;

  &:hover {
    cursor: pointer;
    background-color: rgb(235, 235, 235);
  }
`;

function SearchbarResultListItem({ result, searchValue }) {
  const onClick = useCallback(() => {
    //TODO: add feature to track search by time, e.g. every 5s as long as searchbar is focused
    console.log(result.item, result.item.category, result.item.productId);
    //window._mtm.push({ "event": "custom-search-event", "SearchValue": searchValue }); // tag manager currently does not support site search
    window._paq && window._paq.push(['trackSiteSearch', searchValue, 'Searchbar', 1]);
    let url = window.location.origin;
    url += "/products/" + (result.item.category + "?productId=" + result.item.productId);
    window.location.href = url;
  }, [result]);
  return (
    <StyledListItem onClick={onClick}>
      {result.item.productName}
    </StyledListItem>
  );
}

const StyledList = styled.ul`
  list-style: none;
  list-style-position: initial;
  list-style-type: none;
  overflow-y: auto;
  list-style-type: disc;
  display: block;
  border: 0px solid;
  margin-bottom: 0.1rem;
  padding-left: 0rem;
`;

function SearchbarResultList({ searchResults, searchValue }) {
  //console.log("sresults:", searchResults)
  return (
    <StyledList>
      {searchResults.map((result) => <SearchbarResultListItem key={result.item.productId} result={result} searchValue={searchValue} />)}    </StyledList>
  );
}

export default SearchbarResultList;
