import React, { useCallback, useEffect, useState } from "react";
import TransitionMovie from '../components/container/TransitionMovie';
import { useLocation, useSearchParams } from "react-router-dom";
import ProductsCallouts from "../components/buttons/products-callouts.component";
import LoadingScreen from "../components/container/LoadingScreen";


const Products = ({ changeLayeredMovies, changeBackgroundImage, isBackgroundReady }) => {
  const [searchParams] = useSearchParams();
  const transition = searchParams.get('tx');
  const location = useLocation();

  const [backgroundChanged, setBackgroundChanged] = useState(false);
  const [transitionRunning, setTransitionRunning] = useState(transition !== "0");


  const onTransitionFinished = useCallback(() => {
    setTransitionRunning(false);
  }, [setTransitionRunning]);


  useEffect(() => {
    //window._mtm.push({ 'event': 'mtm.PageView' });
  }, []);

  useEffect(() => {
    const lazyMovie = async () => {
      if (transition === "0") {
        changeBackgroundImage("empty_page.png");
        changeLayeredMovies(["Loop_Produktion_NoAlpha[00000-00249].webm"]); //(["Loop_Produktion_Windraeder_Fluss_[00000-00250].webm"]);
      } else {
        await new Promise((resolve) => setTimeout(resolve, 700));
        changeBackgroundImage("empty_page.png");
        changeLayeredMovies(["Loop_Produktion_NoAlpha[00000-00249].webm"]); //(["Loop_Produktion_Windraeder_Fluss_[00000-00250].webm"]);
      }
      setBackgroundChanged(true);
    };
    lazyMovie();
    //window._mtm.push({ 'event': 'mtm.PageView' });
  }, []);


  if (transitionRunning) {
    return (
      <main>
        <TransitionMovie video="Anflug_Produktion_[00000-00100].webm" onFinished={onTransitionFinished} duration={3950} />
      </main>
    );
  }
  if (backgroundChanged && isBackgroundReady) {
    return (
      <main>
        <ProductsCallouts />
      </main>
    );
  }
  return (
    <LoadingScreen />
  );
}

export default Products;
