import {useEffect, useMemo, useState} from "react";
import {default as axios} from "axios";
import {listTechnologiesQueryConfig} from "../gql";

export function useTechnologyBy(technologies, predicate) {
  return useMemo(() => {
    let technology;
    if (technologies && predicate) {
      technologies.forEach(item => {
        if (predicate(item)) {
          technology = item;
        }
      });
    }
    return technology;
  }, [technologies, predicate]);
}
