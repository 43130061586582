import {useEffect, useMemo, useState} from "react";
import {default as axios} from "axios";
import {listProductsQueryConfig} from "../gql";

export function useProductById(products, productId) {
  return useMemo(() => {
    let resultProduct;
    if (products && productId) {
      products.forEach(product => {
        if (product.productId === productId) {
          resultProduct = product;
        }
      });
    }
    return resultProduct;
  }, [products, productId]);
}
