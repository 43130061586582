import React, { useCallback } from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import { useTechnologies } from "../custom-hooks/useTechnologies";
import { useTechnologyBy } from "../custom-hooks/useTechnologyBy";
import LoadingScreen from "../components/container/LoadingScreen";
import EngineeringDevelopmentRoomView from "../components/technology/engineering-development/EngineeringDevelopmentRoomView"
function TechnologyEDSimulationsRoom({ changeBackgroundImage, changeLayeredMovies }) {
    const technologies = useTechnologies();
    const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "SIMU"); //TODO: add here xyz

    const setupBackground = useCallback(() => {
        changeBackgroundImage("technology/Competence_Simulations_BG_01a.jpg");
        changeLayeredMovies([]); //TODO: change here xyz
    }, [changeBackgroundImage, changeLayeredMovies]);

    return (
        <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_comeptence_simulation.mp4" duration={2200}>
            {!technology && <LoadingScreen />}
            {technology && <EngineeringDevelopmentRoomView technology={technology} />}
        </TransitionMovieContentWrapper>
    );
}

export default TechnologyEDSimulationsRoom;
