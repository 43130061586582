import React, {useEffect, useRef} from 'react';
import {useWindowSize} from "../../../custom-hooks/useWindowSize";
import LocationDetails from "./LocationDetails";
import styled from "styled-components";


const Container = styled.div`
    transition-duration: 500ms;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 25%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    padding: 80px 24px 20px 24px;
`;

const ScrollContainer = styled.div`
    flex: 1 1 auto;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
`;


function DesktopLocationInformationBox({location, onSizeChanged}) {
    const wrapperRef = useRef();
    const windowSize = useWindowSize();
    useEffect(() => {
        if (onSizeChanged && wrapperRef.current) {
            onSizeChanged({width: wrapperRef.current.clientWidth, height: wrapperRef.current.clientHeight});
        }
    }, [windowSize, wrapperRef.current, onSizeChanged]);

    return (
      <Container ref={wrapperRef}>
          <ScrollContainer className="hide-scrollbar">
              <LocationDetails location={location}/>
          </ScrollContainer>
      </Container>
    );
}

export default DesktopLocationInformationBox;
