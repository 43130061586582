import React, {useMemo} from "react";
import styled from "styled-components";
import HtmlParser from "../../../misc/HtmlParser";


const Text = styled.div`
    font-size: 1.1em;
`;

const Heading = styled.div`
    font-size: 12px;
    margin-top: 40px;
    margin-bottom: 13px;
`;

const Subheading = styled.div`
    font-size: 30px;
    margin-bottom: 13px;
`;

function LocationDetails({ location }) {
  const splittedArray = useMemo(() => location.textfull.split("\n"), [location]);
  const splittedAddress = useMemo(() => location.addressFull.split("\n"), [location]);
  const splittedSecAddress = useMemo(() => location.secAddress.length ? location.secAddress[0].split("\n") : [], [location]);
  const splittedSecFull = useMemo(() => location.secAddress.length > 1 ? location.secAddress[1].split("\n") : [], [location]);

  return (
    <>
      <Heading>LOCATIONS</Heading>
      <Subheading>{location.locationName}</Subheading>
      <Text>
        <b>{location.desc}</b><br /><br />
      </Text>
      <Text>
        {splittedAddress.map(part => <span key={part}><HtmlParser string={part} /></span>)}
        <br />
        {splittedArray.map(part => <span key={part}><HtmlParser string={part} /></span>)}
        <br /><br /><br />
        {splittedSecAddress.map(part => <span key={part}><HtmlParser string={part} /></span>)}
        <br />
        {splittedSecFull.map(part => <span key={part}><HtmlParser string={part} /></span>)}
      </Text>
    </>
  );
}

export default LocationDetails;
