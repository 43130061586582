import React, {useContext, useEffect} from "react";
import styled from "styled-components";

import SustainabilityYearSelection from "./components/SustainabilityYearSelection";
import MobileSustainabilityDetails from "./components/MobileSustainabilityDetails";
import SustainabilityCarouselImage from "./components/SustainabilityCarouselImage";
import AppStateContext from "../../contexts/appStateContext";


const MobileViewContainer = styled.div`
  position: absolute;
  top: 65px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const MobileLocationSelectionContainer = styled.div`
  flex: 0 0 auto;
  padding: 16px 10%;
`;

const MobileLocationDetailsContainer = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 15px 10%;
`;

function SustainabilityMobileView({ sustainabilityYears, currentYear, setCurrentYear }) {
  const { setMobileBackButtonVisible, setMobileBackButtonLocation } = useContext(AppStateContext);

  useEffect(() => {
    setMobileBackButtonLocation("/");
    setMobileBackButtonVisible(true);
  }, [setMobileBackButtonLocation, setMobileBackButtonVisible]);

  return (
    <main>
      <SustainabilityCarouselImage productCarouselImage={currentYear.carouselImage} />
      <MobileViewContainer>
        <MobileLocationSelectionContainer>
          <SustainabilityYearSelection sustainabilityYears={sustainabilityYears} currentYear={currentYear} setCurrentYear={setCurrentYear} />
        </MobileLocationSelectionContainer>
        <MobileLocationDetailsContainer>
          <MobileSustainabilityDetails year={currentYear} />
        </MobileLocationDetailsContainer>
      </MobileViewContainer>
    </main>
  );
}

export default SustainabilityMobileView;
