import React, {useCallback} from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import {useTechnologies} from "../custom-hooks/useTechnologies";
import {useTechnologyBy} from "../custom-hooks/useTechnologyBy";
import LoadingScreen from "../components/container/LoadingScreen";
import LightMetalCastingRoomView from "../components/technology/light-metal-casting/LightMetalCastingRoomView";

function TechnologyLightMetalCastingGravityDieCastingRoom({changeBackgroundImage, changeLayeredMovies}) {
  const technologies = useTechnologies();
  const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "GDC");

  const setupBackground = useCallback(() => {
    changeBackgroundImage("technology/Technology_Gravity-Die-Casting_03e.jpg");
    changeLayeredMovies([]);
  },[changeBackgroundImage, changeLayeredMovies]);

  return (
    <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_Technologie_Gravity-Die-Casting_[00000-00068].webm" duration={2350}>
      { !technology && <LoadingScreen /> }
      { technology && <LightMetalCastingRoomView technology={technology} />}
    </TransitionMovieContentWrapper>
  );
}

export default TechnologyLightMetalCastingGravityDieCastingRoom;
