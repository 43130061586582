import React from "react";

/**
 *  Erstellt aus plain text umbrüche und bold text
 * @param string
 * @return {JSX.Element}
 * @constructor
 */
const HtmlParser = ({string}) => {
  // Split the string on <b></b> tags
  const parts = string.split(/<b>|<\/b>/);

  // Create an array of React elements by mapping over the parts array
  const elements = parts.map((part, index) => {
    // Check if the current part is enclosed in <b></b> tags
    if (index % 2 === 1) {
      // If it is, return a <b> element containing the text from the current part
      return <b key={index}>{part}</b>;
    } else {
      // If it isn't, split the part on \n characters and create a <br /> element for each one
      if (part.split('\n').length > 1) {
        return part.split('\n').map((line, lineIndex) => {
          return <React.Fragment key={index + "" + lineIndex}>{line}<br /></React.Fragment>
        });
      } else {
        return part.split('\n').map((line, lineIndex) => {
          return <React.Fragment key={index + "" + lineIndex}>{line}</React.Fragment>
        });
      }
    }
  });
  // Return the array of elements wrapped in a <div>
  return (
    <div>
      {elements}
    </div>
  );
};

export default HtmlParser;
