import '../../App.css';
import { Link } from 'react-router-dom';
import './responsive-callouts.css';
import './hj-callouts.css'
import BackButton from './back-button-menu-hj.component';
import {useWindowSize} from "../../custom-hooks/useWindowSize";
import {useContext, useEffect, useMemo} from "react";
import {useMediaQuery} from "react-responsive";
import classNames from "classnames";
import AppStateContext from "../../contexts/appStateContext";

function GetVideoScaling() {
    try {
        const bgVideos = document.getElementsByClassName('background-video-redesign');
        if (bgVideos.length > 0) {
            let bgVideoScaling = {};
            const videoBackground = bgVideos[0];
            bgVideoScaling.x = videoBackground.clientWidth;
            bgVideoScaling.y = videoBackground.clientHeight;
            return bgVideoScaling;
        } else {
            const bgImage = document.getElementsByClassName('background-layer-still')[0];
            let bgImageScaling = {};
            bgImageScaling.x = bgImage.clientWidth;
            bgImageScaling.y = bgImage.clientHeight;
            return bgImageScaling;
        }
    } catch (e) { }
}

function computeContainerPosition(windowWidth, windowHeight) {
    const absoluteBackgroundSize = GetVideoScaling();

    let theSizeXRight = windowWidth; // for buttons with relative value > 0.5!
    let theSizeXLeft = windowWidth;
    let theSizeY = absoluteBackgroundSize.y;
    if (absoluteBackgroundSize.x > windowWidth) {
        let diff = absoluteBackgroundSize.x - windowWidth;
        let h_diff = diff / 4; // its /4 because i scale on BOTH ends into the image, therefore i only add a quarter of the diff
        theSizeXRight += h_diff;
        theSizeXLeft -= h_diff;
    }

    // CONFIG
    const SIDES_MARGIN_VALUE = 75;
    const BUTTON_WIDTH_VALUE = 400;

    // Position of Container
    let cValue = (theSizeXRight * 0.550);

    // Keep distance on the right
    let colValue_c = cValue + BUTTON_WIDTH_VALUE;
    if (colValue_c >= windowWidth) {
        cValue = (windowWidth - (BUTTON_WIDTH_VALUE + SIDES_MARGIN_VALUE));
    }

    // Keep distance on the left
    cValue = cValue <= SIDES_MARGIN_VALUE ? SIDES_MARGIN_VALUE : cValue;

    return {
        left: (cValue + "px"),
    };
}


function ProductsCallouts(props) {
    const {setMobileBackButtonVisible, setMobileBackButtonLocation} = useContext(AppStateContext);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isDesktopView = isDesktopOrLaptop && !isPortrait;

    useEffect(() => {
        setMobileBackButtonLocation("/");
        setMobileBackButtonVisible(!isDesktopView);
    }, [isDesktopView]);

    const windowSize = useWindowSize();
    const containerPosition = useMemo(
      () => computeContainerPosition(windowSize.width, windowSize.height),
      [windowSize.width, windowSize.height]
    );

    const containerStyle = useMemo(() => {
        if (isDesktopView) {
            return {
                left: containerPosition.left,
            };
        }
        return {};
    }, [isDesktopView, containerPosition]);
    const containerClassNames = classNames("hj-products-callout-container", {"hj-products-callout-container--desktop": isDesktopView});


    return (
      <div className='fade-in-button'>
          <div className={containerClassNames} style={containerStyle}>
              <Link to={"/products/engines"}>
                  <div className='hj-callout-products-wrapper'>
                      <span className='hj-callout-products-content'>ENGINE AND DRIVETRAIN</span>
                  </div>
              </Link>
              <Link to={"/products/transmissions"}>
                  <div className='hj-callout-products-wrapper'>
                      <span className='hj-callout-products-content'>TRANSMISSION</span>
                  </div>
              </Link>
              <Link to={"/products/body-chassis-steering"}>
                  <div className='hj-callout-products-wrapper'>
                      <span className='hj-callout-products-content'>BODY - CHASSIS - STEERING</span>
                  </div>
              </Link>
              <Link to={"/products/electric-mobility"}>
                  <div className='hj-callout-products-wrapper'>
                      <span className='hj-callout-products-content'>ELECTRIC MOBILITY</span>
                  </div>
              </Link>
              { isDesktopView && <BackButton transition="0" navigateTo="/" /> }
          </div>
      </div>
    );
}

export default ProductsCallouts;

/*

function ProductsCallouts(props) {
    return (
        <>
            <div className='fade-in-button'>
                <Link to={"/products/engines"}>
                    <div className='callout-button-container engines'>
                        <div className='callout-text-container' >
                            <div className='hj-callout-products-wrapper'>
                                <span className='hj-callout-products-content'>ENGINE AND DRIVETRAIN</span>
                            </div>
                            {/* <img className='callout-image-engines' src={require('../../assets/callouts/xCallout_line-engine.png')}></img> }
                            </div>
                            </div>
                        </Link>
                        <Link to={"/products/transmissions"}>
                            <div className='callout-button-container transmission'>
                                <div className='callout-text-container'>
                                    <div className='hj-callout-products-wrapper'>
                                        <span className='hj-callout-products-content'>TRANSMISSION</span>
                                    </div>
                                    {/* <img className='callout-image-transmission' src={require('../../assets/callouts/xCallout_line-transmission.png')}></img> }
                                </div>
                            </div>
                        </Link>
                        <Link to={"/products/body-chassis-steering"}>
                            <div className='callout-button-container body-chassis-steering'>
                                <div className='callout-text-container'>
                                    <div className='hj-callout-products-wrapper'>
                                        <span className='hj-callout-products-content'>BODY - CHASSIS - STEERING</span>
                                    </div>
                                    {/* <img className='callout-image-bcs' src={require('../../assets/callouts/xCallout_line-bcs.png')}></img> }
                                </div>
                            </div>
                        </Link>
                        <Link to={"/products/electric-mobility"}>
                            <div className='callout-button-container elmobility'>
                                <div className='callout-text-container'>
                                    <div className='hj-callout-products-wrapper'>
                                        <span className='hj-callout-products-content'>ELECTRIC MOBILITY</span>
                                    </div>
                                    {/* <img className='callout-image-elmobility' src={require('../../assets/callouts/xCallout_line-elmobility.png')}></img> }
                                </div>
                            </div>
                        </Link>
                    </div>
                </>
            );
        }

*/
