import React from 'react';
import { BsFilm, BsLink45Deg } from 'react-icons/bs';
import '../../../../App.css';
import '../../../container/productinformationbox.css'
import HtmlParser from "../../../../misc/HtmlParser";


function ContentFields(props) {
  if (props.content != null) {
    const elements = props.content.keyValueFields.map((content) => {
      switch (content.type) {
        case 'video':
          return (
            <React.Fragment key={content.value}>
              <div id="infobox-video-x" onClick={() => { window._paq && window._paq.push(['trackEvent', "ProductInfobox", 'InfoboxVideoClicked', content.videoHeading]); props.callback(content.value, content.videoHeading) }}><BsFilm style={{ position: 'relative', bottom: '0.0625em' }}></BsFilm> {content.label}</div>
            </React.Fragment>
          );
        case 'weblink':
          return (
            <React.Fragment key={content.value}>
              <div onClick={() => { window._paq && window._paq.push(['trackEvent', "ProductInfobox", 'InfoboxLinkClicked', content.value]); }}>
                <a href={content.value} target="_blank">
                  <BsLink45Deg style={{ position: 'relative', bottom: '0.0625em' }}></BsLink45Deg> {content.label}
                </a>
              </div>
            </React.Fragment>
          );
        default:
          break;
      }
    });

    return (
      <React.Fragment>
        {elements}
      </React.Fragment>
    );
  }
}


function ProductDetails({ product, category, onVideoLinkClicked }) {
  return (
    <>
      <div className='p-infobox-text p-infobox-heading'>{category}</div>
      <div className='p-infobox-text p-infobox-subheading'>{product.boxHeading}</div>
      <div className='p-infobox-text p-infobox-description'>
        <HtmlParser string={product.productDescription} />
      </div>
      {product.content && <div className='p-infobox-text p-infobox-facts-heading'><strong>CONTENT</strong></div>}
      <div className='p-infobox-text p-infobox-content-list'>
        <ContentFields content={product.content} callback={onVideoLinkClicked} />
      </div>
      <br />
    </>
  );
}

export default ProductDetails;
