import { React } from 'react';
import './BackdropModalImprint.css';


function ToggleImprintModal() {
    //console.log("Showing Impressum modal(toggle)");
    const _modal = document.getElementById('backdrop-modal-imprint');
    _modal.classList.toggle('active-modal');
}

function CloseOnClickOutside(evt) {
    if (evt.target.id == "backdrop-modal-imprint") {
        ToggleImprintModal()
    }
}
/*
*   Impressum Popup(in App.js eingebaut)
*/
function BackdropModalImprint() {

    return (
        <>
            <div id='backdrop-modal-imprint' className='backdrop-modal-container' onClick={(event) => CloseOnClickOutside(event)}>
                <div className='backdrop-modal-content-wrapper'>
                    <div className='backdrop-modal-content'>
                        <div className='backdrop-modal-header-banner'>
                            <span className='backdrop-modal-close-button' onClick={() => ToggleImprintModal()}>CLOSE</span>
                        </div>
                        <div className='backdrop-modal-heading'>
                            <h2>IMPRINT</h2>
                        </div>
                        <div className='backdrop-modal-text'>
                            <strong>Editorial work, responsibility for content and holder of responsibility of this website:</strong>
                            <br></br>
                            Albert Handtmann Metallgusswerk GmbH & Co. KG<br></br>
                            Arthur-Handtmann-Str. 25-31<br></br>
                            88400 Biberach<br></br>
                            <br></br>
                            Tel.: +49 7351 342-0<br></br>
                            Fax: +49 7351 342-6470<br></br>
                            info.foundry(at)handtmann.de<br></br>
                            <br></br>
                            Registered office: Biberach, Germany<br></br>
                            Registration Court: Ulm HRA 640674<br></br>
                            VAT ID: DE 144 893 804<br></br>
                            General partner:<br></br>
                            Albert Handtmann Metallgusswerk Verwaltungs-GmbH,<br></br>
                            Registered office:Biberach, Germany<br></br>
                            Registration Court: Ulm HRB 640490<br></br>
                            Managing Directors: Wolfgang Schmidt (Chairman), Heiko Pfeiffer, Dirk Seckler<br></br>
                            <br></br>
                            <br></br>
                            This information also applies in particular to the social media channels operated by the Handtmann Group of Companies (e.g. Facebook, YouTube, etc.).<br></br>
                            <br></br>
                            <br></br>



                            <strong>Legal information</strong><br></br>
                            <br></br>
                            <strong>Copyright</strong><br></br>
                            All texts, images, graphics, audio, video or other visual representations and their arrangement on the sites are subject to copyright protection and other protective laws. The content of these websites must not be copied, distributed, changed or made accessible to third parties for commercial purposes without our express approval. We expressly point out that images contained on the websites may be subject to the copyright of third parties.
                            <br></br><br></br>
                            <strong>Brand protection</strong><br></br>
                            The brands mentioned on all Handtmann web pages are legally protected trademarks and word marks of Albert Handtmann Metallgusswerk GmbH & Co. KG. Brands to which this does not apply are explicitly marked.
                            <br></br><br></br>
                            <br />
                            {/*
                            <br></br> <strong>Design of the website:</strong><br></br>
                            Creapolis Computergrafik und Design GmbH<br></br>
                            info@creapolis.de<br></br>
                            www.creapols.de<br></br>*/}
                        </div>
                        <div className='backdrop-modal-footer-banner'>
                            <span className='backdrop-modal-close-button' onClick={() => ToggleImprintModal()}>CLOSE</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BackdropModalImprint;