//TODO: Make the "config" party dynamic with making "data:" a variable i can change on request
//      right now it uses listProductsQuery as data, 
//      another way would be to use multiple configs, depending on the query

const listProductsQuery = JSON.stringify({
  query: `{
      listProducts (limit: 250) {
        data {
          id
          productId
          category
          productName
          productFiles {
            productImage
            productCarouselImage
            productCarouselPodest
            overviewImage
          }
          informationBox {
            boxHeading
            productDescription
            facts {
              keyValueFields {
                name
                value
              }
            }
            content {
              keyValueFields {
                type
                label
                value
                videoHeading
              }
            }
            homepageLink
            videoLink
          }
          searchKeywords {
            keyword
          }
        }
      }
    }`,
  variables: {}
});

export const listProductsQueryConfig = {
  method: 'post',
  url: 'https://dh1qnw2i6ba2u.cloudfront.net/cms/preview/de-DE',
  headers: {
    //'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    //'Connection': 'keep-alive',
    //'DNT': '1',
    //'Origin': 'https://d18f8ptn8dqhfn.cloudfront.net',
    'Authorization': 'Bearer a44e81c5e75b5e7a0565e411ed6cf1b88e3d13ee48a2a310'
  },
  data: listProductsQuery
};



const listTechnologiesQuery = JSON.stringify({
  query: `{
    listTechnologies (limit: 250) {
      data {
        id
        technologyName
        technologyShortName
        technologyInformationBox
        {
          facts {
            key 
            value
          }
          description
        }
        homepageLink
        videoLink
        searchKeywords {
          keyword
        }
      }
    }
    }`,
  variables: {}
});

export const listTechnologiesQueryConfig = {
  method: 'post',
  url: 'https://dh1qnw2i6ba2u.cloudfront.net/cms/preview/de-DE',
  headers: {
    //'Accept-Encoding': 'gzip, deflate, br',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    //'Connection': 'keep-alive',
    //'DNT': '1',
    //'Origin': 'https://d18f8ptn8dqhfn.cloudfront.net',
    'Authorization': 'Bearer a44e81c5e75b5e7a0565e411ed6cf1b88e3d13ee48a2a310'
  },
  data: listTechnologiesQuery
};