import React, {useCallback} from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import LoadingScreen from "../components/container/LoadingScreen";
import {useTechnologies} from "../custom-hooks/useTechnologies";
import {useTechnologyBy} from "../custom-hooks/useTechnologyBy";
import SystemsEngineeringRoomView from "../components/technology/systems-engineering/SystemsEngineeringRoomView";

function TechnologySystemsEngineeringAssemblyRoom({changeBackgroundImage, changeBackgroundImageStyleOverride, changeLayeredMovies}) {
  const technologies = useTechnologies();
  const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "ASE");

  const setupBackground = useCallback(() => {
    changeBackgroundImage("technology/Technology_ASE_NEW.jpg");
    changeLayeredMovies([]);
  },[changeBackgroundImage, changeLayeredMovies]);

  return (
    <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_Technologie_Assembly Systems Engineering.webm" duration={2150} >
      { !technology && <LoadingScreen /> }
      { technology && <SystemsEngineeringRoomView technology={technology} changeBackgroundImageStyleOverride={changeBackgroundImageStyleOverride} />}
    </TransitionMovieContentWrapper>
  );
}

export default TechnologySystemsEngineeringAssemblyRoom;
