import '../../App.css';
import './back-button.css';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button } from 'bootstrap';
import { BsArrowLeft, BsArrowLeftSquare } from 'react-icons/bs';

function BackButtonProducts(props) {
    //let navigate = useNavigate();
    //let navigateTo = (props.navigateTo + "?tx=" + props.transition)
    
    return (
        <div id="backButton" className="menu-back-button">
            <div className='back-button-container'>
                <div className='back-button-wrapper'>
                    <div className='back-button-content inactive-color'>
                        <BsArrowLeft></BsArrowLeft> back
                    </div>
                </div>
            </div>
            {/*             <div style={buttonDivStyle}>
                <BsArrowLeft style={{border: '0px solid white', backgroundColor: 'white'}} fontWeight={'bold'} fontSize={'36px'} color='black'></BsArrowLeft> back
            </div> */}
        </div>
    );
}

export default BackButtonProducts;