import React, { useContext } from 'react';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import './mobile-navbar.css';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import BackButton from "../buttons/back-button-menu-hj.component";
import AppStateContext from "../../contexts/appStateContext";
import SearchBar from "./searchbar/searchbar.component";
import styled from "styled-components";


function ToggleImprintModal() {
    const _modal = document.getElementById('backdrop-modal-imprint');
    if (_modal) {
        _modal.classList.toggle('active-modal');
    }
    try { window._paq && window._paq.push(['trackEvent', "ImprintModal", 'ModalToggled', 'Imprint']); } catch (e) { }
}

function TogglePrivacyModal() {
    const _modal = document.getElementById('backdrop-modal-privacy');
    if (_modal) {
        _modal.classList.toggle('active-modal');
    }
    try { window._paq && window._paq.push(['trackEvent', "PrivacyModal", 'ModalToggled', 'Privacy']); } catch (e) { }
}

function ToggleMobileMenu() {
    const menu = document.getElementById("mobile-navbar-menu");
    menu.classList.toggle("show-menu");
    try {
        menu.scrollTo({ top: 0, behavior: 'auto' });
    } catch (e) { }
    try { window._paq && window._paq.push(['trackEvent', "BurgerMenu", 'MenuToggled']); } catch (e) { }
}

function ToggleBurgerMenu() {
    const button = document.getElementById("bm-button");
    button.classList.toggle('bm-change');
    ToggleMobileMenu();
}

const Spacer = styled.div`
    flex: 1 1 0;
`;

function MobileNavbar() {
    const { mobileBackButtonVisible, mobileBackButtonLocation } = useContext(AppStateContext);
    const navigate = useNavigate();
    const BASE_URL = "https://showroom.handtmann.de/";

    return (
        <div className='mobile-navbar-container'>
            <div id="bm-button" className="bm-container" onClick={ToggleBurgerMenu}>
                <div className="bar1" />
                <div className="bar2" />
                <div className="bar3" />
            </div>
            <SearchBar style={{ flex: "0 1 auto" }} />
            <Spacer />
            {mobileBackButtonVisible && mobileBackButtonLocation && <BackButton navigateTo={mobileBackButtonLocation} transition={"0"} />}
            <div id='mobile-navbar-menu' className='mobile-navbar-menu-container'>
                <div className='mobile-navbar-menu-wrapper'>
                    <ListGroup style={{ marginBottom: '45px', marginTop: '15px' }}>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                LANDINGPAGE
                            </span>
                        </ListGroupItem>
                        <br />
                        <ListGroupItem>
                            <span className='mobile-navbar-menu-heading'>
                                PRODUCTS:
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/products/engines?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                ENGINES
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/products/transmissions?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                TRANSMISSION
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/products/body-chassis-steering?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                BODY - CHASSIS - STEERING
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/products/electric-mobility?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                ELECTRIC MOBILITY
                            </span>
                        </ListGroupItem>
                        <ListGroupItem>
                            <span className='mobile-navbar-menu-heading'>
                                COMPETENCES:
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/competences/battery-assembly-systems-engineering?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                SYSTEMS-ENGINEERING
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/competences/high-pressure-die-casting?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                HIGH-PRESSURE DIE CASTING
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/competences/gravity-die-casting?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                GRAVITY DIE CASTING
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/competences/machining-and-assembly?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                ASSEMBLY
                            </span>
                        </ListGroupItem>
                        <br />
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/sustainability?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                SUSTAINABILITY
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { navigate("/locations?tx=0"); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                LOCATIONS
                            </span>
                        </ListGroupItem>
                        <a target="_blank" href={"https://www.handtmann.de/en/light-metal-casting"}>
                            <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { ToggleBurgerMenu(); }}>
                                <span className='mobile-navbar-menu-item'>
                                    ABOUT US <BsBoxArrowUpRight style={{ float: "right" }} ></BsBoxArrowUpRight>
                                </span>
                            </ListGroupItem>
                        </a>
                        <br></br>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { TogglePrivacyModal(); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                PRIVACY
                            </span>
                        </ListGroupItem>
                        <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { ToggleImprintModal(); ToggleBurgerMenu(); }}>
                            <span className='mobile-navbar-menu-item'>
                                IMPRINT
                            </span>
                        </ListGroupItem>
                        <a target="_blank" href={"https://www.linkedin.com/company/albert-handtmann-metallgusswerk-gmbh-&-co-kg/mycompany/?viewAsMember=true"}>
                            <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { ToggleBurgerMenu(); }}>
                                <span className='mobile-navbar-menu-item'>
                                    <FaLinkedin style={{ marginBottom: "5px" }}></FaLinkedin> LINKED IN <BsBoxArrowUpRight style={{ float: "right" }} />
                                </span>
                            </ListGroupItem>
                        </a>
                        <a target="_blank" href={"https://www.youtube.com/user/HandtmannMetallguss"}>
                            <ListGroupItem className='mobile-navbar-menu-item-button' onClick={() => { ToggleBurgerMenu(); }}>
                                <span className='mobile-navbar-menu-item'>
                                    <FaYoutube style={{ marginBottom: "4px" }}></FaYoutube> YOUTUBE <BsBoxArrowUpRight style={{ float: "right" }} />
                                </span>
                            </ListGroupItem>
                        </a>
                        <br />
                        <br />
                    </ListGroup>
                </div>
            </div>
        </div>
    );
}


export default MobileNavbar;
