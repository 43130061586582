import {createContext} from "react";

const AppStateContext = createContext({
  mobileBackButtonVisible: false,
  setMobileBackButtonVisible: (visible) => {},

  mobileBackButtonLocation: null,
  setMobileBackButtonLocation: (location) => {},
});

export default AppStateContext;
