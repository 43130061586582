import '../../App.css';
import React, {useCallback, useMemo} from 'react';
import { useLocation } from 'react-router-dom';
import './BackgroundContainer.css';
import { useMediaQuery } from 'react-responsive'
import {useWindowSize} from "../../custom-hooks/useWindowSize";

/**
 *
 * @param windowWidth
 * @param windowHeight
 * @return {{width: string, height: string}}
 */
function computeImageAndVideoSize(windowWidth, windowHeight) {
    const aspectRatio = 1920 / 1080;
    const windowAspectRatio = windowWidth / windowHeight;
    if (windowAspectRatio > aspectRatio) {
        return {
            width: '100vw',
            height: 'auto',
        };
    }
    return {
        width: 'auto',
        height: '100vh',
    };
}

/*
*   Die zentrale Komponente für den Hintergrund auf allen Seiten.
*   Weiß nicht, ob das der beste Weg ist aber es ist so gewachsen da wir es nicht
*   hinbekommen haben, dass die Transition Videos schön aussehen wenn.
*   Durch unterschiedliche Internetgeschwindigkeiten hatten wir beim Laden manchmal weißes aufblitzen bis der erste Frame da war
*   Dadurch dass die Transition Videos sich über den Hintergrund legen und dieser dann ausgewechselt wird konnten wir
*   das einigermaßen umgehen und verbessern so. Bringt aber natürlich andere Probleme mit sich.
*   war nur am Anfang ein sehr wichtiger Punkt mit den Kameraflügen zu/in die Gebäude
*/
function BackgroundContainer({ onLayeredMovieReady, onBaseImageLoaded, layeredMovies, baseImage, baseImageStyleOverride, mobileBackground, mobilePosition }) {
    // TODO cleanup component
    const location = useLocation();
    const windowSize = useWindowSize();

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 700px)' });
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 699px)' });
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    const isOverSize = useMediaQuery({ query: '(min-height: 1080px)' });

    const onLayeredMovieReadyCallback = useCallback(() => {
        if (onLayeredMovieReady) {
            onLayeredMovieReady();
        }
    }, [onLayeredMovieReady]);

    const onBaseImageLoadedCallback = useCallback(() => {
        if (onBaseImageLoaded) {
            onBaseImageLoaded();
        }
    }, [onBaseImageLoaded]);

    const imageAndVideoSize = useMemo(
      () => computeImageAndVideoSize(windowSize.width, windowSize.height),
      [windowSize.width, windowSize.height]
    );

    //const BASE_WEBM_PATH = "../../assets/webm/movs/";
    //const BASE_STILL_PATH = "../../assets/webm/stills/";

    const imgStyle = useMemo(() => {
        return Object.assign({
            width: imageAndVideoSize.width,
            height: imageAndVideoSize.height,
        }, baseImageStyleOverride || {});
    }, [imageAndVideoSize.width, imageAndVideoSize.height, baseImageStyleOverride]);

    if (
      isDesktopOrLaptop
      || location.pathname === "/"
      || location.pathname === "/locations"
      || location.pathname === "/sustainability"
      || location.pathname === "/products"
      || location.pathname === "/products/engines"
      || location.pathname === "/products/body-chassis-steering"
      || location.pathname === "/products/transmissions"
      || location.pathname === "/products/electric-mobility"
      || location.pathname === "/competences"
      || location.pathname === "/competences/battery-assembly-systems-engineering"
      || location.pathname === "/competences/high-pressure-die-casting"
      || location.pathname === "/competences/gravity-die-casting"
      || location.pathname === "/competences/machining-and-assembling"
      || location.pathname === "/competences/battery-systems"
      || location.pathname === "/competences/mega-casting"
      || location.pathname === "/competences/optimisation"
      || location.pathname === "/competences/rapid-prototyping"
    ) { //TODO:xTRUE
        return (
            <React.Fragment>
                <div className='bg-video-container-redesign'>
                    <div className="bg-video-div-redesign" id="endvr_bg_video">
                        <div id="bg-video-div-id">
                            {layeredMovies.map((movie, index) =>
                                <video key={movie}
                                       className='background-video-redesign'
                                       style={{width: imageAndVideoSize.width, height: imageAndVideoSize.height, aspectRatio: "1920 / 1080"}}
                                       onCanPlay={ index === 0 ? onLayeredMovieReadyCallback : undefined }
                                       preload='auto' autoPlay playsInline loop muted>
                                    <source src={require("../../assets/webm/movs/" + movie)} type='video/webm' />
                                </video>
                            )}
                            <img className='background-layer-still'
                                 style={imgStyle}
                                 width={1920} height={1080}
                                 alt=''
                                 onLoad={onBaseImageLoadedCallback}
                                 src={require(("../../assets/webm/stills/" + baseImage))} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    else if (isTabletOrMobile &&
        (location.pathname === "/competences/machining-and-assembling"
          || location.pathname === "/competences/gravity-die-casting"
            || location.pathname === "/competences/high-pressure-die-casting"
          || location.pathname === "/competences/battery-assembly-systems-engineering"
          || location.pathname === "/competences/battery-systems"
          || location.pathname === "/competences/rapid-prototyping"
          || location.pathname === "/competences/optimisation"
          || location.pathname === "/competences/mega-casting"
          )
    ) {
        //console.log("new responsive backgrond");
        return (
            <React.Fragment>
                <div className='mobile-background-responsive-container'>
                    <div className='mobile-background-responsive' style={{ backgroundImage: `url(${mobileBackground})` }} id='mobile-background-container-landscape' />
                </div>
            </React.Fragment>
        )
    }
    else if (isTabletOrMobile) {
        //console.log("pathname: ", location.pathname);
        // only on landingpage
        let theZoom = 1;
        (isOverSize) ? theZoom = 1.4 : theZoom = 1;
        if (location.pathname === "/") {
            // portrait
            if (isPortrait) {
                //console.log("Is PORTRAIT mode on mobile");
                return (
                    <React.Fragment>
                        <div style={{ zoom: theZoom }} id='mobile-background-container' className='mobile-landingpage-background mobile-landingpage-background-sProducts' />
                    </React.Fragment>
                )
            }
            else {
                // landscape
                //console.log("Is LANDSCAPE mode on mobile");
                return (
                    <React.Fragment>
                        <div style={{}} id='mobile-background-container' className='mobile-landingpage-background mobile-landingpage-background-sProducts' />
                    </React.Fragment>
                )
            }
        }
        // all other pages
        else {
            if (isPortrait) {
                // portrait mode
                //console.log("Is PORTRAIT mode on mobile");
                return (
                    <React.Fragment>
                        <div style={{ background: `url(${mobileBackground}) ${mobilePosition}` }} id='mobile-background-container' className='mobile-page-background mobile-landingpage-background-sProducts' />
                    </React.Fragment>
                )
            }
            else {
                // landscape mode
                //console.log("Is LANDSCAPE mode on mobile");
                return (
                    <React.Fragment>
                        <div className='mobile-page-background-wrapper'>
                            <div style={{ backgroundImage: `url(${mobileBackground})` }} id='mobile-background-container-landscape' className='mobile-page-background-landscape' />
                        </div>
                    </React.Fragment>
                )
            }
        }
    }
}

export default BackgroundContainer;
