import React, { useCallback } from "react";
import styled from "styled-components";


const StyledButton = styled.button`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 12px;
  border: none;
  outline: none;
  background-color: var(${props => props.active ? "--main-handtmann-color" : "--main-handtmann-color-inactive"});
  opacity: ${props => props.active ? 1 : 0.95};
  color: white;
  min-height: 34px;
  text-align: center;
  font-size: 18.24px;
  font-weight: bold;
  line-height: 34px;
  user-select: none;
  
  &:not(:last-child) {
    margin-bottom: 3%;
  }
`;

function SelectionButton({ year, currentYear, setCurrentYear }) {
  const selectCallback = useCallback(() => {
    try { window._paq && window._paq.push(['trackEvent', "Sustainability", 'YearClicked', year.year.toString()]); } catch (e) { }
    setCurrentYear(year);
  }, [year, setCurrentYear]);

  return (
    <StyledButton onClick={selectCallback} active={year.year === currentYear.year}>
      {year.year}
    </StyledButton>
  );
}

function SustainabilityYearSelection({ sustainabilityYears, currentYear, setCurrentYear }) {
  return (
    <>
      {sustainabilityYears.map(year => <SelectionButton key={year.year} year={year} currentYear={currentYear} setCurrentYear={setCurrentYear} />)}
    </>
  );
}

export default SustainabilityYearSelection;
