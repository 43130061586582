import React, {useCallback} from "react";
import TransitionMovieContentWrapper from "../components/container/TransitionMovieContentWrapper";
import {useTechnologies} from "../custom-hooks/useTechnologies";
import {useTechnologyBy} from "../custom-hooks/useTechnologyBy";
import LoadingScreen from "../components/container/LoadingScreen";
import LightMetalCastingRoomView from "../components/technology/light-metal-casting/LightMetalCastingRoomView";

function TechnologyLightMetalCastingHighPressureDieCastingRoom({changeBackgroundImage, changeLayeredMovies}) {
  const technologies = useTechnologies();
  const technology = useTechnologyBy(technologies, (item) => item.technologyShortName === "HPDC");

  const setupBackground = useCallback(() => {
    changeBackgroundImage("empty_page.png");
    changeLayeredMovies(["Loop_Technologie_Pressure-Die-Casting.webm"]);
  },[changeBackgroundImage, changeLayeredMovies]);

  return (
    <TransitionMovieContentWrapper setupBackground={setupBackground} video="Anflug_Technologie_Pressure-Die-Casting_[00000-00068].webm" duration={2200}>
      { !technology && <LoadingScreen /> }
      { technology && <LightMetalCastingRoomView technology={technology} />}
    </TransitionMovieContentWrapper>
  );
}

export default TechnologyLightMetalCastingHighPressureDieCastingRoom;
