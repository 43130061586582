import { React } from 'react';
import './BackdropModalPrivacy.css';


function TogglePrivacyModal() {
    //console.log("Showing Impressum modal(toggle)");
    const _modal = document.getElementById('backdrop-modal-privacy');
    _modal.classList.toggle('active-modal');
}

function CloseOnClickOutside(evt) {
    if (evt.target.id == "backdrop-modal-privacy") {
        TogglePrivacyModal()
    }
}
/*
*   Datenschutz Popup(in App.js eingebaut)
*/
function BackdropModalPrivacy() {
    return (
        <>
            <div id='backdrop-modal-privacy' className='backdrop-modal-container' onClick={(event) => CloseOnClickOutside(event)}>
                <div className='backdrop-modal-content-wrapper'>
                    <div className='backdrop-modal-content'>
                        <div className='backdrop-modal-header-banner'>
                            <span className='backdrop-modal-close-button' onClick={() => TogglePrivacyModal()}>CLOSE</span>
                        </div>
                        <div className='backdrop-modal-heading'>
                            <h2>DATENSCHUTZ</h2>
                        </div>
                        <div className='backdrop-modal-text'>
                            <br></br>
                            <p>Wir, die Handtmann Unternehmensgruppe, freuen uns über Ihren Besuch auf unseren Internetseiten und über Ihr Interesse an unseren Produkten und unserem Unternehmen. Wir nehmen den Schutz Ihrer personenbezogenen Daten ernst und möchten, dass Sie sich beim Besuch unserer Internetseiten sicher und wohl fühlen. Ein Besuch und die Nutzung unserer Internetseiten ist grundsätzlich ohne die Angabe von personenbezogenen Daten möglich.</p>
                            <p>Grundsätzlich haben wir als Verantwortliche für die Verarbeitung zahlreiche technische und organisatorische Maßnahmen umgesetzt um einen möglichst lückenlosen Schutz der über diese Internetseiten erhobenen und verarbeiteten personenbezogenen Daten zu gewährleisten. Ein absoluter Schutz besteht allerdings auch hier nicht, weil internetbasierende Datenübertragungen grundsätzlich Sicherheitslücken aufweisen können. Es steht Ihnen als betroffene Person deshalb frei, jederzeit auch alternative Wege der Datenübermittlung an uns zu nutzen.</p>
                            <p>Bitte beachten Sie, dass wir nicht für andere Anbieter garantieren können, deren Internetseiten Sie eventuell über Links auf unseren Internetseiten erreichen.</p>
                            <p>Bedenken Sie, dass die Datenübertragung im Internet grundsätzlich mit Sicherheitslücken bedacht sein kann. Ein vollumfänglicher Schutz vor dem Zugriff durch Fremde ist nicht realisierbar.</p>
                            <br></br>
                            <br></br>
                            <p><strong>Grundsätzliches und Verantwortliche Stelle</strong></p>
                            <p>Der Schutz Ihrer personenbezogenen Daten ist uns wichtig, deshalb werden alle anfallenden personenbezogenen Daten (z. Bsp. Vorname, Name, Adresse, eMail-Adresse, Telefonnummern, etc.) entsprechend den gültigen Datenschutzbestimmungen wie der EU-Datenschutz-Grundverordnung (EU-DS-GVO), des Bundesdatenschutzgesetz-neu (BDSG-neu) und des Telemediengesetz (TMG) erhoben und verarbeitet.</p>
                            <p>Verantwortliche Stelle im Sinne der EU-DS-GVO ist:</p>
                            <p>
                                Albert Handtmann Metallgusswerk GmbH & Co. KG<br></br>
                                Arthur-Handtmann-Strasse 25-31<br></br>
                                88400 Biberach<br></br>
                                <br></br>
                                Tel. +49 7351 342 0<br></br>
                                E-mail: info.foundry(at)handtmann.de<br></br>
                                <br></br>
                                Geschäftsführer: Wolfgang Schmidt (Vorsitzender), Heiko Pfeiffer, Dirk Seckler: Wolfgang Schmidt (Chairman), Heiko Pfeiffer, Dirk Seckler<br></br>
                            </p>
                            <p>Sofern innerhalb unseres Internetangebots die Möglichkeit zur Eingabe Ihrer persönlichen oder geschäftlichen Daten besteht - beispielsweise im Rahmen einer Newsletter Anmeldung oder eines Kontaktformulars - sind die anzugebenden Pflichtangaben auf das notwendige Minimum begrenzt um den beabsichtigten Zweck erfüllen zu können. Die von Ihnen angegebenen Daten speichern wir für die jeweils genannten Zwecke.</p>
                            <p>Zu sonstigen Marketingzwecken nutzen wir Ihre Daten nur, wenn Sie zuvor von uns über den Verwendungszweck ausführlich informiert wurden und Sie ausdrücklich eingewilligt haben. Die Abmeldung von Newslettern und der Widerruf von Einwilligungen sind jederzeit mit Wirkung für die Zukunft möglich.</p>
                            <br></br>
                            <br></br>
                            <strong>Speicherdauer</strong><br /><br />

                            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                            <br /><br />


                            <strong>Hosting und Content Delivery Networks (CDN)</strong> <br /><br />

                            Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend AWS).
                            <br /><br />
                            Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier: https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
                            <br /><br />
                            Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS: https://aws.amazon.com/de/privacy/?nc1=f_pr.
                            <br /><br />
                            Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.
                            <br /><br />
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active.
                            <br /><br />
                            <strong>Auftragsverarbeitung</strong>
                            <br /><br />
                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                            <br /><br />
                            <strong>Amazon CloudFront CDN</strong>
                            <br /><br />
                            Wir nutzen das Content Delivery Network Amazon CloudFront CDN. Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg (nachfolgend „Amazon“).
                            <br /><br />
                            Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes Content Delivery Network. Dabei wird technisch der Informationstransfer zwischen Ihrem Browser und unserer Website über das Content Delivery Network geleitet. Hierdurch können wir die weltweite Erreichbarkeit und die Leistungsfähigkeit unserer Website erhöhen.
                            <br /><br />
                            Der Einsatz von Amazon CloudFront CDN beruht auf unserem berechtigten Interesse an einer möglichst fehlerfreien und sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
                            <br /><br />
                            Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.
                            <br /><br />
                            Weitere Informationen zu Amazon CloudFront CDN finden Sie hier: https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf.
                            <br /><br />
                            Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt0000000TOWQAA4&status=Active.
                            <br /><br />
                            <strong>Auftragsverarbeitung</strong>
                            <br /><br />
                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                            <br /><br /><br /><br />



                            <p><strong>Weitergabe von Personenbezogenen Daten an Dritte:</strong>
                                <br>
                                </br>
                                Ihre personenbezogenen Daten werden grundsätzlich nur denjenigen Personen in unseren Unternehmen zur Verfügung gestellt, die mit der Bearbeitung Ihrer Anfrage oder Ihrer Anmeldung zum Newsletter befasst sind. Eine Weitergabe Ihrer personenbezogenen Daten an Dritte kann erfolgen, wenn Sie der Weitergabe ausdrücklich zugestimmt haben oder die Datenschutzgesetze eine solche Weitergabe zulassen. In einigen Fällen erfolgt unsere Datenverarbeitung bei einem weisungsgebundenen Auftragsdatenverarbeiter, der gemäß den datenschutzrechtlichen Vorgaben verpflichtet ist und die Daten nicht zu einem anderen als dem vorgegebenen Zweck verwenden darf.</p>
                            <br></br>
                            <p>
                                <strong>Rechte der Betroffenen</strong><br></br><br></br>
                                Als Betroffene, deren personenbezogene Daten von uns erhoben und verarbeitet werden, haben Sie das Recht (auf Antrag) unentgeltliche Auskunft über die von uns zu Ihrer Person gespeicherten Daten zu erhalten. Unrichtige Daten müssen wir auf Ihr Verlangen hin berichtigen. Ausserdem können Sie jederzeit die Löschung (oder Sperrung) Ihrer Daten verlangen - sofern dieser Löschung keine anderen gesetzlichen oder vertraglichen Grundlagen widersprechen. Ist eine Löschung nicht möglich, so können Sie alternativ eine Pseudonymisierung oder eine Anonymisierung verlangen. Der Nutzung Ihrer Daten können Sie jederzeit mit Wirkung für die Zukunft widersprechen.
                            </p>
                            <p><br></br>
                                <strong>Rechtsgrundlage der Verarbeitung </strong><br></br><br></br>
                                Die Grundlage für die hier beschriebenen Verarbeitungen finden sich in Art. 6, Abs. 1, Satz 1, lit. a (Einwilligung) oder in lit. b (vorvertragliche Massnahmen) oder in lit. c (Erfüllung einer rechtlichen Verpflichtung) der EU-DS-GVO.                            </p>
                            Letztlich können von uns ausgeübte Verarbeitungen auf Art. 6, Satz 1, lit. f beruhen, wenn sie dazu dienen, ein berechtigtes Interesse unserer Unternehmen oder Dritter auszuüben. Dies kann erfolgen, solange die Interessen, Grundrechte und Grundfreiheiten eines Betroffenen nicht überwiegen. Unser berechtigtes Interesse besteht regelmäßig darin, unsere Geschäftstätigkeit zugunsten und zum Nutzen unserer Eigner und Mitarbeiter durchführen zu können.
                            <br></br><br></br><br></br>
                            <strong>Speicherdauer</strong>  <br /><br />

                            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
                            <br /><br /><br />
                            <p>
                                <strong>Kontakt des Datenschutzbeauftragten</strong><br></br><br></br>
                                Bei Fragen hinsichtlich der Erhebung und Verarbeitung Ihrer personenbezogenen Daten wenden Sie sich bitte an unseren Datenschutzbeauftragten:
                                <br></br><br></br>
                                Albert Handtmann Metallgusswerk GmbH & Co. KG<br></br>
                                Klaus Zell<br></br>
                                Arthur-Handtmann-Strasse 25<br></br>
                                88400 Biberach<br></br>
                                <br></br>
                                Tel. +49 7351 342 2900<br></br>
                                E-mail: dsb(at)handtmann.de
                            </p>
                            <br></br>
                            <p>Darüber hinaus können Sie sich bei Bedarf jederzeit beim Landesbeauftragten für den Datenschutz und die Informationsfreiheit des Landes Baden-Württemberg (Deutschland) (https://www.baden-wuerttemberg.datenschutz.de/online-beschwerde) beschweren.</p>
                            <br></br>

                            <strong>Cookies</strong>
                            <br /> <br />

                            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
                            <br /><br />

                            Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
                            <br /><br />
                            Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden.
                            <br /><br />
                            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.
                            <br /><br />
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                            <br /><br />
                            Welche Cookies und Dienste auf dieser Website eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.

                            <br /><br />
                            <br></br>
                            <strong>Einwilligung mit USERCENTRICS</strong><br></br>
                            <br></br>
                            Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Website: https://usercentrics.com/de/ (im Folgenden „Usercentrics“).
                            <br /><br />
                            Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten an Usercentrics übertragen:
                            <br /><br />
                            - Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en) <br />
                            - Ihre IP-Adresse<br />
                            - Informationen über Ihren Browser<br />
                            - Informationen über Ihr Endgerät<br />
                            - Zeitpunkt Ihres Besuchs auf der Website<br />
                            - Geolocation<br />
                            <br />
                            Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, das Usercentrics-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.
                            <br /><br />
                            Das Usercentrics-Banner auf dieser Website wurde mit Hilfe von eRecht24 konfiguriert. Das erkennen Sie daran, dass im Banner das Logo von eRecht24 auftaucht. Um das eRecht24-Logo im Banner auszuspielen, wird eine Verbindung zum Bildserver von eRecht24 hergestellt. Hierbei wird auch die IP-Adresse übertragen, die jedoch nur in anonymisierter Form in den Server-Logs gespeichert wird. Der Bildserver von eRecht24 befindet sich in Deutschland bei einem deutschen Anbieter. Das Banner selbst wird ausschließlich von Usercentrics zur Verfügung gestellt.
                            <br /><br />
                            Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz bestimmter Technologien einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.
                            <br /><br />





                            <br />

                            <strong>Auftragsverarbeitung</strong><br /><br />
                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.


                            <br /><br />
                            <strong>Anfrage per E-Mail, Telefon oder Telefax</strong><br /><br />

                            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                            <br /><br />
                            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.
                            <br /><br />
                            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
                            <br /><br />


                            <strong>Analyse-Tool Matomo</strong><br /><br />

                            Diese Website benutzt den Open Source Webanalysedienst Matomo.
                            <br /><br />
                            Mit Hilfe von Matomo sind wir in der Lage Daten über die Nutzung unserer Website durch die Websitebesucher zu erfassen und zu analysieren. Hierdurch können wir u. a. herausfinden, wann welche Seitenaufrufe getätigt wurden und aus welcher Region sie kommen. Außerdem erfassen wir verschiedene Logdateien (z. B. IP-Adresse, Referrer, verwendete Browser und Betriebssysteme) und können messen, ob unsere Websitebesucher bestimmte Aktionen durchführen (z. B. Klicks, Käufe u. Ä.).
                            <br /><br />
                            Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
                            <br /><br />
                            <strong>IP-Anonymisierung</strong>
                            <br /><br />
                            Bei der Analyse mit Matomo setzen wir IP-Anonymisierung ein. Hierbei wird Ihre IP-Adresse vor der Analyse gekürzt, sodass Sie Ihnen nicht mehr eindeutig zuordenbar ist.
                            <br /><br />


                            <strong>Auftragsverarbeitung</strong> <br /><br />

                            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.
                            <br />
                            <br></br>
                            <br></br>
                            <strong>Datensparsamkeit</strong><br></br>
                            <br></br>
                            Personenbezogene Daten speichern wir gemäß den Grundsätzen der Datenvermeidung und der Datensparsamkeit nur so lange, wie es dem Speicherzweck nach erforderlich ist oder vom Gesetzgeber vorgeschrieben wird (beispielsweise gesetzliche Aufbewahrungsfristen). Entfällt der Zweck, der der Erhebung und Nutzung der Daten zugrunde lag oder endet die Speicher- bzw. Aufbewahrungsfrist, dann löschen bzw. sperren wir die Daten.
                            <br></br>
                            <br></br>
                            <strong>Diese Datenschutzerklärung gilt ab dem 14.06.2024</strong>
                        </div>
                        <div className='backdrop-modal-footer-banner'>
                            <span className='backdrop-modal-close-button' onClick={() => TogglePrivacyModal()}>CLOSE</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default BackdropModalPrivacy;