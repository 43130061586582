import '../../App.css';
import {useWindowSize} from "../../custom-hooks/useWindowSize";
import {useEffect, useMemo} from "react";

function computeVideoSize(windowWidth, windowHeight) {
  const aspectRatio = 1920 / 1080;
  const windowAspectRatio = windowWidth / windowHeight;
  if (windowAspectRatio > aspectRatio) {
    return {
      width: '100vw',
      height: 'auto',
    };
  }
  return {
    width: 'auto',
    height: '100vh',
  };
}

/*
* Die Componente Rendert einfach ein Video über dem Rest um einen Anflug an die Gebäude sauber hinzubekommen
* Mehr infos dazu in der Mail
*/
function TransitionMovie({video, callback, duration, onFinished}) {
  const windowSize = useWindowSize();
  const videoSize = useMemo(
    () => computeVideoSize(windowSize.width, windowSize.height),
    [windowSize.width, windowSize.height]
  );

  useEffect(() => {
    let timer;
    if (duration != null && onFinished != null) {
      timer = setTimeout(onFinished, duration);
    }
    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [duration, onFinished]);

  return (
    <div className="globalContainer" id="global_container">
      <div className="scroll-div" id="endvr_scroll_div" >
        <div id="endvr_scroll_contaner_div">
          <div className="bg-video-div-redesign" id="endvr_bg_video">
            <div className="fade-in-transition-movie">
              <video id='transition-video'
                     style={{width: videoSize.width, height: videoSize.height, aspectRatio: '1920 / 1080'}}
                     autoPlay muted playsInline
                     onPlay={callback}>
                <source src={require('../../assets/webm/movs/' + video + '')} type='video/mp4' />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransitionMovie;
